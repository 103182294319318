import * as t from "io-ts";
import { PreferenceEnumV } from "@type/core/preferences/preference.types";

export const preferenceDetailsResponseDtoDecoderContent = {
  id: t.string,
  userId: t.string,
  preferenceType: PreferenceEnumV,
  value: t.string,
};

export const preferenceDetailsResponseDtoDecoder = t.type(
  preferenceDetailsResponseDtoDecoderContent,
);

export type PreferenceDetailsResponseDto = t.TypeOf<
  typeof preferenceDetailsResponseDtoDecoder
>;
