import { FunctionComponent, ReactNode } from "react";
import { InfoCircleOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";

interface PropsType {
  title: ReactNode | string;
}

const FormFieldTooltip: FunctionComponent<PropsType> = ({ title }) => {
  return (
    <>
      <Tooltip className="form-tooltip" title={title}>
        <InfoCircleOutlined />
      </Tooltip>
      &nbsp;
    </>
  );
};

export default FormFieldTooltip;
