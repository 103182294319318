import * as t from "io-ts";

export const ProofFileContentResponseDtoDecoderContent = {
  base64Data: t.string,
  mimeType: t.string,
  filename: t.string,
};

export const ProofFileContentResponseDtoDecoder = t.type(
  ProofFileContentResponseDtoDecoderContent,
);

export type ProofFileContentResponseDto = t.TypeOf<
  typeof ProofFileContentResponseDtoDecoder
>;
