export enum FeatureRight {
  CREATE = "CREATE",
  GET = "GET",
  GET_SELF = "GET_SELF",
  UPDATE = "UPDATE",
  SEARCH = "SEARCH",
  DELETE = "DELETE",
  UPDATE_SELF = "UPDATE_SELF",
}

export interface RightParams {
  code: FeatureRight;
  color: string;
}

export const featureRightProperties: Record<FeatureRight, RightParams> = {
  CREATE: { code: FeatureRight.CREATE, color: "#49cc90" },
  GET: { code: FeatureRight.GET, color: "#61affe" },
  GET_SELF: { code: FeatureRight.GET_SELF, color: "#61affe" },
  UPDATE: { code: FeatureRight.UPDATE, color: "#fca130" },
  UPDATE_SELF: { code: FeatureRight.UPDATE_SELF, color: "#fca130" },
  SEARCH: { code: FeatureRight.SEARCH, color: "#61affe" },
  DELETE: { code: FeatureRight.DELETE, color: "#f93e3e" },
};
