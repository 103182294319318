import configuration from "@utils/Config";
import { createEffect } from "effector";
import {
  restDetailsHandler,
  restListHandler,
  restListMultipartHandler,
  restPostHandler,
} from "@utils/rest/RestClient";
import { LicenseImportRequestDto } from "@state/licenses/dto/request/license.import.request.dto";
import * as t from "io-ts";
import {
  LicenseHeldResponseDto,
  licenseHeldResponseDtoDecoder,
  licenseHeldResponseDtoDecoderContent,
} from "@state/licenses/dto/response/license.held.response.dto";
import {
  LicenseManagedResponseDto,
  licenseManagedResponseDtoDecoder,
  licenseManagedResponseDtoDecoderContent,
} from "@state/licenses/dto/response/license.managed.response.dto";

const licenseBaseUrl = `${configuration.backendBaseUrl}/licenses`;
const importLicenseByKeyUrl = `${licenseBaseUrl}/add`;
const importLicenseByFileUrl = `${licenseBaseUrl}/import`;

const LicenseManagedResponseDtoArrayDecoder = t.array(
  licenseManagedResponseDtoDecoder,
);

export const requestImportLicenseByKey = createEffect({
  handler: restPostHandler<
    LicenseImportRequestDto,
    LicenseManagedResponseDto[],
    typeof licenseManagedResponseDtoDecoder,
    typeof LicenseManagedResponseDtoArrayDecoder
  >(importLicenseByKeyUrl, undefined, LicenseManagedResponseDtoArrayDecoder),
});

export const requestGetManagedLicenses = createEffect({
  handler: restListHandler<
    LicenseManagedResponseDto,
    Record<string, unknown>,
    typeof licenseManagedResponseDtoDecoder,
    typeof LicenseManagedResponseDtoArrayDecoder
  >(licenseBaseUrl),
});

export const requestGetHeldLicense = createEffect({
  handler: restDetailsHandler<
    LicenseHeldResponseDto,
    typeof licenseHeldResponseDtoDecoderContent,
    typeof licenseHeldResponseDtoDecoder
  >(licenseBaseUrl + "/me", undefined, licenseHeldResponseDtoDecoder),
});

export const requestRejectLicense = createEffect({
  handler: restPostHandler<
    null,
    null,
    typeof licenseManagedResponseDtoDecoderContent,
    typeof licenseManagedResponseDtoDecoder
  >(`${licenseBaseUrl}/reject`, undefined, licenseManagedResponseDtoDecoder),
});

export const requestImportLicenseByFile = createEffect({
  handler: restListMultipartHandler<
    LicenseManagedResponseDto,
    typeof licenseManagedResponseDtoDecoder,
    typeof LicenseManagedResponseDtoArrayDecoder
  >(importLicenseByFileUrl, undefined, LicenseManagedResponseDtoArrayDecoder),
});

export const requestAttributeLicense = createEffect({
  handler: restPostHandler<
    null,
    LicenseManagedResponseDto,
    typeof licenseManagedResponseDtoDecoderContent,
    typeof licenseManagedResponseDtoDecoder
  >(licenseBaseUrl + "/", undefined, licenseManagedResponseDtoDecoder),
});

export const requestRemoveLicense = createEffect({
  handler: restPostHandler<
    null,
    LicenseManagedResponseDto,
    typeof licenseManagedResponseDtoDecoderContent,
    typeof licenseManagedResponseDtoDecoder
  >(licenseBaseUrl + "/", undefined, licenseManagedResponseDtoDecoder),
});
