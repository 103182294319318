import { FunctionComponent } from "react";
import "@utils/i18n/i18n";
import E2EHook from "@components/core/e2e/E2EHook";
import CookiesBanner from "@components/core/banners/CookiesBanner";
import configuration from "@utils/Config";
import ToastAlert from "@components/core/alerts/ToastAlert";
import { Routes } from "react-router-dom";
import coreRoutes from "@routes/core/CoreRoutes";
import AppInfosRibbon from "@components/core/ribbons/AppInfosRibbon";
import "@/styles/styles.scss";
import "@/utils/i18n/i18n";
import { ConfigProvider } from "antd";
import "dayjs/locale/fr";
import updateLocale from "dayjs/plugin/updateLocale";
import locale from "antd/es/locale/fr_FR";
import dayjs from "dayjs";

dayjs.extend(updateLocale);
dayjs.updateLocale("fr", {
  weekStart: 1,
});
export const App: FunctionComponent = () => {
  return (
    <ConfigProvider
      csp={{ nonce: getNonceValue() }}
      wave={{ disabled: true }}
      locale={locale}
      theme={{
        token: {
          colorPrimary: "#0D578B",
          colorLink: "#0D578B",
          colorSuccess: "#6FCF97",
          colorWarning: "#F2994A",
          colorError: "#EB5757",
          fontSize: 16,
          lineHeight: 1.2,
          colorTextHeading: "#003E79",
          colorText: "#000000",
          colorTextSecondary: "#003E79",
          colorTextDisabled: "#C9C9C9",
          borderRadius: 6,
          colorBorder: "#003E79",
          boxShadow: "0 4px 24px 0 rgba(0, 0, 0, 0.12)",
          fontFamily: "'SourceSans', sans-serif",
        },
      }}
    >
      <div className="global">
        <E2EHook />
        <CookiesBanner />
        {configuration.appInfosActive && <AppInfosRibbon />}
        <ToastAlert />
        <Routes>{coreRoutes}</Routes>
      </div>
    </ConfigProvider>
  );
};

const getNonceValue = (): string => {
  const nonceValueMeta = document.querySelector("meta[name='csp-nonce']");
  const content = nonceValueMeta?.getAttribute("content");
  return content ? content : "";
};

export default App;
