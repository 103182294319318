import { modulesProperties } from "@utils/enums/api/module.enum";
import { ApiErrorCodeDuplicates } from "@type/core/api/api.types";
import i18n, { TOptions } from "i18next";
import { toastError } from "@utils/helpers/toast-helper";

const moduleIndex = 0;
const featureIndex = 1;
const errorIndex = 2;

const computeErrorLabelKey = (errorCode: string): string => {
  const v = errorCode.split("-");

  const prefix = ["api", "errors", "codes"];

  if (v.length === 1) {
    return [...prefix, v[0]].join(".");
  }

  return [
    ...prefix,
    modulesProperties[v[moduleIndex]].code,
    v[featureIndex],
    v[errorIndex],
  ].join(".");
};

const computeErrorCodesDuplicates = (
  values: string[],
): ApiErrorCodeDuplicates => {
  return values.reduce(function (acc: ApiErrorCodeDuplicates, obj: string) {
    const cle = obj;
    if (!acc[cle]) {
      acc[cle] = 0;
    }
    acc[cle] = acc[cle] + 1;
    return acc;
  }, {});
};

export const showErrorFromApiErrorCode = (
  errorCode: string,
  params?: TOptions,
): void => {
  toastError(i18n.t(computeErrorLabelKey(errorCode), params));
};

export const apiHelper = {
  computeErrorLabelKey,
  computeErrorCodesDuplicates,
  showErrorFromApiErrorCode,
};
