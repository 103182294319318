import * as t from "io-ts";
import { EndpointMethodEnumV } from "@type/core/endpoint/endpoint.types";

export const endpointDetailsResponseDtoDecoderContent = {
  url: t.union([t.string, t.null]),
  method: t.union([EndpointMethodEnumV, t.null]),
  version: t.union([t.string, t.null]),
  permissions: t.union([t.string, t.null]),
  description: t.union([t.string, t.null]),
  access: t.union([t.string, t.null]),
};

export const endpointDetailsResponseDtoDecoder = t.type(
  endpointDetailsResponseDtoDecoderContent,
);

export type EndpointDetailsResponseDto = t.TypeOf<
  typeof endpointDetailsResponseDtoDecoder
>;
