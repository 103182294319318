import * as t from "io-ts";
import { createEffect } from "effector";
import { restListHandler, restPostHandler } from "@utils/rest/RestClient";
import configuration from "@/utils/Config";
import {
  PreferenceDetailsResponseDto,
  preferenceDetailsResponseDtoDecoder,
  preferenceDetailsResponseDtoDecoderContent,
} from "./dto/response/preference.details.response.dto";
import { PreferenceSaveRequestDto } from "./dto/request/preference.save.request.dto";

const preferenceBaseUrl = `${configuration.backendBaseUrl}/preferences`;
const userPreferenceUrl = `${preferenceBaseUrl}/me`;

const preferenceDetailsResponseDtoArrayDecoder = t.array(
  preferenceDetailsResponseDtoDecoder,
);

export const requestGetUserPreferences = createEffect({
  handler: restListHandler<
    PreferenceDetailsResponseDto,
    Record<string, unknown>,
    typeof preferenceDetailsResponseDtoDecoder,
    typeof preferenceDetailsResponseDtoArrayDecoder
  >(userPreferenceUrl),
});

export const requestCreateOrUpdatePreference = createEffect({
  handler: restPostHandler<
    PreferenceSaveRequestDto,
    PreferenceDetailsResponseDto,
    typeof preferenceDetailsResponseDtoDecoderContent,
    typeof preferenceDetailsResponseDtoDecoder
  >(`${preferenceBaseUrl}/`, undefined, preferenceDetailsResponseDtoDecoder),
});
