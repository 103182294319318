import * as t from "io-ts";

export const deviceDetailsResponseDtoDecoderContent = {
  name: t.string,
  statusDate: t.string,
};

export const deviceDetailsResponseDtoDecoder = t.type(
  deviceDetailsResponseDtoDecoderContent,
);

export type DeviceDetailsResponseDto = t.TypeOf<
  typeof deviceDetailsResponseDtoDecoder
>;
