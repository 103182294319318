import * as t from "io-ts";

export const transactionDetailsResponseDtoDecoderContent = {
  transactionId: t.string,
  name: t.string,
  type: t.string,
  responseFilename: t.string,
  document: t.any,
  recipient: t.any,
};

export const transactionDetailsResponseDtoDecoder = t.type(
  transactionDetailsResponseDtoDecoderContent,
);

export type TransactionDetailsResponseDto = t.TypeOf<
  typeof transactionDetailsResponseDtoDecoder
>;
