import * as t from "io-ts";
import { EndpointMethod } from "@utils/enums/endpoint/endpoint.method.enum";
import { FeatureRight } from "@utils/enums/endpoint/feature.right.enum";

export const EndpointMethodEnumV = t.keyof(
  Object.fromEntries(
    Object.keys(EndpointMethod).map((p) => [p, null]),
  ) as Record<EndpointMethod, null>,
);

export const FeatureRightEnumV = t.keyof(
  Object.fromEntries(Object.keys(FeatureRight).map((p) => [p, null])) as Record<
    FeatureRight,
    null
  >,
);
