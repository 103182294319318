import * as t from "io-ts";

export const passwordStrengthConstraintsDetailsResponseDtoDecoderContent = {
  minCharacters: t.number,
  minUpperCaseLetters: t.number,
  minLowerCaseLetters: t.number,
  minSpecialCharacters: t.number,
  minDigits: t.number,
};

export const passwordStrengthConstraintsDetailsResponseDtoDecoder = t.type(
  passwordStrengthConstraintsDetailsResponseDtoDecoderContent,
);

export type PasswordStrengthConstraintsDetailsResponseDto = t.TypeOf<
  typeof passwordStrengthConstraintsDetailsResponseDtoDecoder
>;
