import { FunctionComponent } from "react";
import BasicButton from "@components/core/buttons/BasicButton";
import { ROUTES } from "@routes/Routes";
import PageLayout from "@components/core/layouts/PageLayout";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";

const detailsSearchParamName = "details";

const UnauthorizedScreen: FunctionComponent = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParam] = useSearchParams();

  return (
    <PageLayout
      container={false}
      contentInCard
      bodyClassName="app-error-screen h-100 d-flex align-items-center justify-content-center"
    >
      <div className="error-text-container py-12 d-flex flex-column align-items-center justify-content-center">
        <div className="error-text-unauthorized mt-16 mb-32">
          {t("unauthorized.title")}
        </div>
        {searchParam.get(detailsSearchParamName) && (
          <div className="text-center">
            {t(
              `unauthorized.details.${searchParam.get(detailsSearchParamName)}`,
            )}
          </div>
        )}
        <BasicButton
          text={t("error.goBack")}
          onClick={() => {
            navigate(ROUTES.connected.home.generate());
          }}
          className="mt-24"
        />
      </div>
    </PageLayout>
  );
};

export default UnauthorizedScreen;
