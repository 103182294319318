import { ChangeEvent, FunctionComponent, useEffect, useState } from "react";
import Section from "@components/core/section/Section";
import { useTranslation } from "react-i18next";
import { toastError, toastSuccess } from "@utils/helpers/toast-helper";
import { FIELD_LENGTH, formValidateTriggers } from "@utils/Constant";
import { Col, Form, Row } from "antd";
import InputFormField from "@components/core/inputs/InputFormField";
import { Store, ValidateErrorEntity } from "rc-field-form/lib/interface";
import SubmitButton from "@components/core/buttons/SubmitButton";
import { CheckOutlined } from "@ant-design/icons";
import PasswordValidationTooltip from "@components/core/tooltips/PasswordValidationTooltip";
import { PasswordStrengthConstraintsDetailsResponseDto } from "@state/auth/dto/response/password.strength.constraints.details.response.dto";
import { requestGetPasswordStrengthConstraints } from "@state/auth/AuthEffects";
import { passwordHelper } from "@utils/helpers/password-helper";
import { requestUpdateUserPassword } from "@state/users/UserEffects";
import { PasswordUpdateRequestDto } from "@state/users/dto/request/password.update.request.dto";
import { formHelper } from "@utils/helpers/form-helper";

const prefix = "users.edit.form";

const MyPasswordForm: FunctionComponent = () => {
  const [passwordValidationFailed, setPasswordValidationFailed] =
    useState<boolean>(false);

  const [password, setPassword] = useState<string>("");
  const [passwordStrengthConstraints, setPasswordStrengthConstraints] =
    useState<PasswordStrengthConstraintsDetailsResponseDto>();

  useEffect(() => {
    void requestGetPasswordStrengthConstraints({ id: "" }).then((result) => {
      setPasswordStrengthConstraints(result.data);
    });
  }, []);

  const onChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const value = e.target.value;
    setPassword(value);
    if (
      value === "" ||
      (passwordStrengthConstraints &&
        passwordHelper.validate(value, passwordStrengthConstraints))
    ) {
      setPasswordValidationFailed(false);
    } else {
      setPasswordValidationFailed(true);
    }
  };

  // GENERIC HOOKS
  const { t } = useTranslation();
  const [form] = Form.useForm();

  useEffect(() => {
    return requestUpdateUserPassword.done.watch(({ result }) => {
      if (result.ok) {
        toastSuccess(t("users.edit.password.messages.success"));
        form.resetFields();
      } else if (result.responseCode !== 401) {
        formHelper.handleFormErrors(
          "users.edit.form.main.fields",
          form,
          result,
        );
      } else {
        toastError(t("error.401"));
      }
    });
  });

  const handleSubmit = () => {
    const userToSave: PasswordUpdateRequestDto = {
      previousCredential: form.getFieldValue("previousCredential") as string,
      credential: form.getFieldValue("credential") as string,
      confirmCredential: form.getFieldValue("confirmCredential") as string,
    };

    void requestUpdateUserPassword({
      dto: userToSave,
    });
  };

  const onFinishFailed = ({ errorFields }: ValidateErrorEntity<Store>) => {
    toastError(t("forms.errors.failed-validation"));
    form.scrollToField(errorFields[0].name);
  };

  return (
    <div className="mt-42">
      <Section title={t("users.sections.myPassword.title")}>
        <Form
          {...formValidateTriggers}
          layout="vertical"
          form={form}
          onFinish={handleSubmit}
          onFinishFailed={onFinishFailed}
        >
          <Row gutter={16}>
            <Col xs={24} sm={24}>
              <InputFormField
                form={form}
                module={prefix}
                field="previousCredential"
                required={true}
                type="password"
                maxLength={FIELD_LENGTH.NAME}
              />
            </Col>
            <Col xs={24} sm={24}>
              <InputFormField
                form={form}
                module={prefix}
                field="credential"
                required={true}
                onChange={onChange}
                type="password"
                maxLength={FIELD_LENGTH.NAME}
              />
              {passwordValidationFailed && passwordStrengthConstraints && (
                <div data-test="error-credential">
                  <PasswordValidationTooltip
                    password={password}
                    strengthConstraints={passwordStrengthConstraints}
                  />
                </div>
              )}
            </Col>
            <Col xs={24} sm={24}>
              <InputFormField
                form={form}
                module={prefix}
                field="confirmCredential"
                type="password"
                required={true}
                maxLength={FIELD_LENGTH.NAME}
                rules={[
                  ({ getFieldValue }) => ({
                    validator(rule, value) {
                      if (!value || getFieldValue("credential") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error(
                          t(
                            "registration.form.fields.passwordConfirmation.errors.unmatch",
                          ),
                        ),
                      );
                    },
                  }),
                ]}
              />
            </Col>
          </Row>
          <div className="pt-26 d-flex align-items-center justify-content-end flex-wrap">
            <SubmitButton
              variant="primary"
              size="sm"
              module={prefix}
              label="submit-password"
              icon={<CheckOutlined />}
              iconRight
            />
          </div>
        </Form>
      </Section>
    </div>
  );
};
export default MyPasswordForm;
