import { FunctionComponent, JSX } from "react";
import ShapeSection from "@static/core/img/shape-section.svg?react";

interface SectionProps {
  title?: string;
  rightContent?: string | JSX.Element;
  description?: string;
  children: string | JSX.Element;
  className?: string;
  titleClassName?: string;
  dataTestFor?: string;
}

const Section: FunctionComponent<SectionProps> = ({
  title,
  rightContent,
  description,
  children,
  className = "",
  titleClassName = "",
  dataTestFor,
}) => {
  return (
    <section className={`app-section ${className}`}>
      <div className="section-header">
        {title && (
          <h3
            className={`section-header-title ${titleClassName}`}
            data-test="section-title"
            data-alt={title}
            data-test-for={dataTestFor}
          >
            <ShapeSection />
            {title}
          </h3>
        )}
        {rightContent && (
          <div className="section-header-right-content">{rightContent}</div>
        )}
      </div>
      {description && <p className="section-description">{description}</p>}
      <div className="section-content" data-test={dataTestFor}>
        {children}
      </div>
    </section>
  );
};

export default Section;
