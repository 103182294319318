import { FunctionComponent, useState } from "react";
import { Form } from "antd";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import BasicButton from "@components/core/buttons/BasicButton";
import { requestForgotPassword } from "@state/auth/AuthEffects";
import SubmitButton from "@components/core/buttons/SubmitButton";
import { formValidateTriggers } from "@utils/Constant";
import { toastError, toastSuccess } from "@utils/helpers/toast-helper";
import { ROUTES } from "@routes/Routes";
import { useForm } from "antd/lib/form/Form";
import LoginLayout from "@components/core/layouts/LoginLayout";
import InputFormField from "@components/core/inputs/InputFormField";

interface ForgotPasswordFormData {
  identifier: string;
}

const ForgotPasswordScreen: FunctionComponent = () => {
  const [form] = useForm();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);

  const InitialValues = {
    email: "",
  };

  const handleSubmit = (values: ForgotPasswordFormData) => {
    setLoading(true);

    requestForgotPassword({ dto: { identifier: values.identifier } })
      .then((): void => {
        toastSuccess(
          t("forgot-password.messages.success", {
            login: values.identifier,
          }),
        );
      })
      .catch((error: unknown) => {
        toastError((error as Error).message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <LoginLayout title={t("forgot-password.title")}>
      <>
        <Form
          form={form}
          onFinish={handleSubmit}
          initialValues={InitialValues}
          {...formValidateTriggers}
        >
          <InputFormField
            form={form}
            module="forgot-password"
            field="identifier"
            className="mb-16"
            required
          />
          <div className="pt-8 d-flex flex-column align-items-stretch gap-24">
            <SubmitButton
              module="forgot-password.form"
              isSubmitting={loading}
              label="reset"
              disabled={loading}
            />
            <BasicButton
              text={t("buttons.back")}
              variant="transparent"
              onClick={() => {
                navigate(ROUTES.auth.login.generate());
              }}
            />
          </div>
        </Form>
      </>
    </LoginLayout>
  );
};

export default ForgotPasswordScreen;
