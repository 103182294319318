import { FunctionComponent, JSX } from "react";
import { useTranslation } from "react-i18next";
import { Upload, UploadProps } from "antd";

const { Dragger } = Upload;

interface ImportFilesCardProps {
  name: string;
  icon: JSX.Element;
  title: string;
  subtitle?: string;
  multiple?: boolean;
  accept?: string;
  className?: string;
  props?: UploadProps;
}

const ImportFilesCard: FunctionComponent<ImportFilesCardProps> = ({
  name,
  icon,
  title,
  props,
  subtitle,
  multiple = false,
  className = "",
}) => {
  const { t } = useTranslation();

  return (
    <Dragger
      className={`app-card-import-files ${className}`}
      name={`import-files-${name}`}
      multiple={multiple}
      showUploadList={false}
      {...props}
    >
      <div className="app-card-import-files-container">
        <div className="card-import-files-icon">{icon}</div>
        <div className="card-import-files-title-container">
          <p className="card-import-files-title">{title}</p>
          {subtitle && <p className="card-import-files-subtitle">{subtitle}</p>}
        </div>
        <p className="card-import-files-description">
          <span>{t("dashboard.header.importFiles.dragPdf")}</span>
          <span>{t("dashboard.header.importFiles.or")}</span>
          <span>{t("dashboard.header.importFiles.importPdf")}</span>
        </p>
      </div>
    </Dragger>
  );
};

export default ImportFilesCard;
