import { Col, Form, Row, Spin } from "antd";
import { FunctionComponent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Field } from "@type/core/form/field.types";
import { LoadingOutlined, CheckOutlined } from "@ant-design/icons";
import { requestSelfUpdateUser } from "@state/users/UserEffects";
import { formHelper } from "@utils/helpers/form-helper";
import { Store, ValidateErrorEntity } from "rc-field-form/lib/interface";
import { FIELD_LENGTH, formValidateTriggers } from "@utils/Constant";
import InputFormField from "@components/core/inputs/InputFormField";
import MailFormField from "@components/core/inputs/MailFormField";
import { SelectFormField } from "@components/core/inputs/SelectFormField";
import { PROFILES_OPTIONS } from "@utils/enums/profile.enum";
import { requestGetUserInfo } from "@state/auth/AuthEffects";
import { UserSelfUpdateRequestDto } from "@state/users/dto/request/user.self.update.request.dto";
import { toastError, toastSuccess } from "@utils/helpers/toast-helper";
import { showConfirm } from "@components/core/modals/ConfirmModal";
import { AuthenticatedUserResponseDto } from "@state/auth/dto/response/authenticated.user.response.dto";
import SubmitButton from "@components/core/buttons/SubmitButton";
import i18n from "i18next";
import Section from "@components/core/section/Section";

export interface Props {
  originAuthUser: AuthenticatedUserResponseDto;
}

const prefix = "users.edit.form";

interface MyAccountFormData {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
}

const MyAccountForm: FunctionComponent<Props> = (props: Props) => {
  // PROPS
  const { originAuthUser } = props;

  // GENERIC HOOKS
  const { t } = useTranslation();
  const [form] = Form.useForm();

  // STATES
  const [user, setUser] =
    useState<AuthenticatedUserResponseDto>(originAuthUser);

  const [fields, setFields] = useState<Field[]>([]);

  const [loading, setLoading] = useState<boolean>(false);

  const [isUserRetrieved, setIsUserRetrieved] = useState<boolean>(false);

  const spinIcon = <LoadingOutlined spin />;

  // EFFECTS
  useEffect(() => {
    if (originAuthUser.id) {
      void requestGetUserInfo({});
    }
  }, [originAuthUser]);

  useEffect(() => {
    return requestGetUserInfo.done.watch(({ result }) => {
      setIsUserRetrieved(true);
      if (result.ok && result.data) {
        const user = result.data;
        setUser(user);
        setFields([
          {
            name: ["firstName"],
            value: user.firstName,
          },
          {
            name: ["lastName"],
            value: user.lastName,
          },
          {
            name: ["email"],
            value: user.email ? user.email : undefined,
          },
          {
            name: ["profile"],
            value: user.profiles[0],
          },
          {
            name: ["ordinalNumber"],
            value: user.ordinalNumber ? user.ordinalNumber : undefined,
          },
          {
            name: ["phoneNumber"],
            value: user.phoneNumber ? user.phoneNumber : undefined,
          },
        ]);
      } else if (result.responseCode !== 401) {
        toastError(t("users.errors.not-found"));
      } else {
        toastError(t("error.401"));
      }
    });
  });

  useEffect(() => {
    return requestSelfUpdateUser.done.watch(({ result }) => {
      setLoading(false);
      if (result.ok && result.data) {
        setUser({
          ...user,
          firstName: result.data.firstName,
          lastName: result.data.lastName,
          email: result.data.email,
          phoneNumber: result.data.phoneNumber,
        });
        toastSuccess(t("users.edit.messages.success"));
      } else if (result.responseCode !== 401) {
        formHelper.handleFormErrors(
          "users.edit.form.main.fields",
          form,
          result,
        );
      } else {
        toastError(t("error.401"));
      }
    });
  });

  const handleSubmit = (values: MyAccountFormData) => {
    void showConfirm({
      title: t("users.edit.modals.save.title"),
      content: t("users.edit.modals.save.content"),
    }).then((confirmed: boolean) => {
      if (confirmed) {
        setLoading(true);
        const userToSave: UserSelfUpdateRequestDto = {
          firstName: values.firstName,
          lastName: values.lastName,
          email: values.email,
          phoneNumber: values.phoneNumber,
        };
        void requestSelfUpdateUser({
          dto: userToSave,
        });
      }
    });
  };

  const onFinishFailed = ({ errorFields }: ValidateErrorEntity<Store>) => {
    toastError(t("forms.errors.failed-validation"));
    form.scrollToField(errorFields[0].name);
  };

  return (
    <div className="mt-42">
      {!isUserRetrieved ? (
        <div className="text-center">
          <Spin indicator={spinIcon} />
        </div>
      ) : (
        <Section title={t("users.sections.myPersonalInfos.title")}>
          <Form
            {...formValidateTriggers}
            layout="vertical"
            form={form}
            onFinish={handleSubmit}
            onFinishFailed={onFinishFailed}
            fields={fields}
          >
            <Row gutter={16}>
              <Col xs={24} sm={12}>
                <InputFormField
                  form={form}
                  module={prefix}
                  field="lastName"
                  required={true}
                  maxLength={FIELD_LENGTH.NAME}
                />
              </Col>
              <Col xs={24} sm={12}>
                <InputFormField
                  form={form}
                  module={prefix}
                  field="firstName"
                  required={true}
                  maxLength={FIELD_LENGTH.NAME}
                />
              </Col>
            </Row>
            <Row gutter={16}>
              <Col xs={24}>
                <MailFormField
                  form={form}
                  type="email"
                  module={prefix}
                  field="email"
                  required={true}
                  maxLength={FIELD_LENGTH.NAME}
                />
              </Col>
            </Row>
            <Row gutter={16}>
              <Col xs={24} sm={12}>
                <SelectFormField
                  form={form}
                  module={prefix}
                  field="profile"
                  required={true}
                  options={PROFILES_OPTIONS}
                />
              </Col>
              <Col xs={24} sm={12}>
                <InputFormField
                  form={form}
                  module={prefix}
                  field="ordinalNumber"
                  maxLength={FIELD_LENGTH.NAME}
                />
              </Col>
            </Row>
            <Row gutter={16}>
              <Col xs={24} sm={12}>
                <InputFormField
                  form={form}
                  module={prefix}
                  field="phoneNumber"
                  required={false}
                  maxLength={FIELD_LENGTH.PHONE_NUMBER}
                  rules={[
                    {
                      pattern: /^[+]?[(]?\d{3}[)]?[-\s.]?\d{3}[-\s.]?\d{4,6}$/,
                      message: (
                        <span>
                          {i18n.t("forms.errors.phone-number-invalid")}
                        </span>
                      ),
                    },
                  ]}
                />
              </Col>
            </Row>
            <div className="pt-26 d-flex align-items-center justify-content-end flex-wrap">
              <SubmitButton
                variant="primary"
                size="sm"
                isSubmitting={loading}
                module={prefix}
                label="submit-information"
                icon={<CheckOutlined />}
                iconRight
              />
            </div>
          </Form>
        </Section>
      )}
    </div>
  );
};
export default MyAccountForm;
