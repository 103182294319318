import {
  ChangeEvent,
  FormEvent,
  FunctionComponent,
  ReactNode,
  KeyboardEvent,
  JSX,
} from "react";
import { Form, FormInstance, Input } from "antd";
import { useTranslation } from "react-i18next";
import { InternalNamePath } from "rc-field-form/lib/interface";
import { Rule } from "antd/es/form";
import { formHelper } from "@utils/helpers/form-helper";
import { basicMandatoryRule } from "@components/core/inputs/InputFormRules";
import FormFieldLabel from "@components/core/labels/FormFieldLabel";
import FloatingLabel from "@components/core/inputs/FloatingLabel";

export interface InputFormFieldProps {
  module: string;
  field: string | InternalNamePath;
  required?: boolean;
  emptyErrorMessage?: string;
  placeholder?: string;
  showTooltip?: boolean;
  labelTooltip?: ReactNode;
  readOnly?: boolean;
  labelClassName?: string;
  fieldClassName?: string;
  maxLength?: number;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  onPressEnter?: (event: KeyboardEvent<HTMLInputElement>) => void;
  type?: string;
  addonAfter?: string | JSX.Element;
  addonBefore?: string;
  textArea?: boolean;
  className?: string;
  fieldPrefix?: string;
  rules?: Rule[];
  onInput?: (e: FormEvent<HTMLInputElement>) => void;
  initialValue?: { [key in string]: string } | string;
  disabled?: boolean;
  form: FormInstance;
}

const InputFormField: FunctionComponent<InputFormFieldProps> = (
  props: InputFormFieldProps,
) => {
  const {
    showTooltip,
    labelTooltip,
    module,
    field,
    required,
    emptyErrorMessage,
    readOnly,
    labelClassName = "",
    fieldClassName = "",
    onChange,
    onPressEnter,
    maxLength,
    type,
    addonAfter,
    addonBefore,
    placeholder,
    className = "",
    fieldPrefix = "",
    rules = [],
    onInput,
    initialValue,
    disabled,
    form,
  } = props;
  const { t } = useTranslation();

  const isRequired: boolean = readOnly ? false : !!required; // !(!var) => return false if var = false | null | undefined

  const i18nField = formHelper.getI18nFieldFromField(field);

  const key = `field_${String(field)}`.replace(",", "_");

  const inputPlaceholder = placeholder
    ? placeholder
    : t(`${module}.fields.${i18nField}.placeholder`);

  return (
    <FloatingLabel
      form={form}
      field={
        typeof field === "object" ? field : `${fieldPrefix}${String(field)}`
      }
      label={
        <FormFieldLabel
          field={field}
          module={module}
          label={inputPlaceholder}
          className={labelClassName}
          showTooltip={showTooltip}
          tooltipLabel={labelTooltip}
        />
      }
    >
      <Form.Item
        name={
          typeof field === "object" ? field : `${fieldPrefix}${String(field)}`
        }
        key={key}
        rules={[
          basicMandatoryRule({
            field,
            emptyErrorMessage,
            constraints: { isRequired, isBlankAllowed: true },
          }),
          ...rules,
        ]}
        className={className}
        initialValue={initialValue}
      >
        <Input
          disabled={readOnly ?? disabled}
          className={`${readOnly ? "readonly-form-field" : ""} ${fieldClassName}`}
          placeholder={inputPlaceholder}
          id={key}
          onChange={onChange}
          onPressEnter={onPressEnter}
          maxLength={maxLength}
          type={type ? type : "text"}
          addonAfter={addonAfter ? addonAfter : ""}
          addonBefore={addonBefore ? addonBefore : ""}
          onInput={onInput}
          data-testid="input-form-field"
          data-test-type="input-text"
          data-test={field}
          data-alt={inputPlaceholder}
        />
      </Form.Item>
    </FloatingLabel>
  );
};

export default InputFormField;
