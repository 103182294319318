import * as t from "io-ts";
import { AuthenticatedUserResponseDto } from "@state/auth/dto/response/authenticated.user.response.dto";
import { OtpMode } from "@utils/enums/otp.mode.enum";

export interface AccessControlContext {
  accessAttempts?: number;
  lastAccessAttemptDate?: string;
}

export interface AuthenticationContext {
  user?: AuthenticatedUserResponseDto;
  accessControl?: AccessControlContext;
}

export const OtpModeEnumV = t.keyof(
  Object.fromEntries(Object.keys(OtpMode).map((p) => [p, null])) as Record<
    OtpMode,
    null
  >,
);
