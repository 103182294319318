import { createEffect } from "effector";
import {
  restListHandlerPagination,
  restPostHandler,
} from "@utils/rest/RestClient";
import configuration from "@utils/Config";
import {
  createPageableDecoder,
  createPageableDecoderContent,
} from "@type/core/list/pagination.types";
import {
  AuthorizationItemResponseDto,
  authorizationItemResponseDtoDecoder,
  authorizationItemResponseDtoDecoderContent,
} from "@state/authorization/response/authorization.item.response.dto";
import {
  AuthorizationDetailResponseDto,
  authorizationDetailResponseDtoDecoder,
} from "@state/authorization/response/authorization.detail.response.dto";

const authorizationBaseUrl = `${configuration.backendBaseUrl}/authorizations`;

const authorizationPageableDecoderContent = createPageableDecoderContent(
  authorizationDetailResponseDtoDecoder,
);

const authorizationPageableDecoder = createPageableDecoder(
  authorizationDetailResponseDtoDecoder,
);

export const requestGetUserAuthorizations = createEffect({
  handler: restListHandlerPagination<
    AuthorizationDetailResponseDto,
    Record<string, unknown>,
    typeof authorizationPageableDecoderContent,
    typeof authorizationPageableDecoder
  >(authorizationBaseUrl, authorizationPageableDecoder),
});

export const requestAuthorizeVeterinarySoftware = createEffect({
  handler: restPostHandler<
    null,
    AuthorizationItemResponseDto,
    typeof authorizationItemResponseDtoDecoderContent,
    typeof authorizationItemResponseDtoDecoder
  >(
    `${authorizationBaseUrl}/`,
    "/authorize",
    authorizationItemResponseDtoDecoder,
  ),
});

export const requestBlockVeterinarySoftware = createEffect({
  handler: restPostHandler<
    null,
    AuthorizationItemResponseDto,
    typeof authorizationItemResponseDtoDecoderContent,
    typeof authorizationItemResponseDtoDecoder
  >(`${authorizationBaseUrl}/`, "/block", authorizationItemResponseDtoDecoder),
});
