export enum ModuleEnum {
  AUTH = "01",
  USER = "02",
  RESOURCE = "03",
  ADMIN = "04",
  CONFIGURATION = "05",
  PREFERENCE = "06",
  ACCOUNT = "07",
}

export interface ModuleProperties {
  code: string;
}

export type ModulesProperties = {
  [key in string]: ModuleProperties;
};

export const modulesProperties: ModulesProperties = {
  [ModuleEnum.AUTH]: {
    code: "AUTH",
  },
  [ModuleEnum.USER]: {
    code: "USER",
  },
  [ModuleEnum.RESOURCE]: {
    code: "RESOURCE",
  },
  [ModuleEnum.ADMIN]: {
    code: "ADMIN",
  },
  [ModuleEnum.CONFIGURATION]: {
    code: "CONFIGURATION",
  },
  [ModuleEnum.PREFERENCE]: {
    code: "PREFERENCE",
  },
  [ModuleEnum.ACCOUNT]: {
    code: "ACCOUNT",
  },
};
