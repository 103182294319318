import * as t from "io-ts";

export const userUpdateResponseDtoDecoderContent = {
  firstName: t.string,
  lastName: t.string,
  email: t.string,
  phoneNumber: t.union([t.string, t.null]),
};

export const userUpdateResponseDtoDecoder = t.type(
  userUpdateResponseDtoDecoderContent,
);

export type UserDetailsResponseDto = t.TypeOf<
  typeof userUpdateResponseDtoDecoder
>;
