import { OptionType } from "@type/core/form/field.types";

export enum AutorisationStatus {
  AUTHORIZED = "AUTHORIZED",
  BLOCKED = "BLOCKED",
}

export interface AutorisationStatusProperties {
  label: string;
  variant: string;
}

export const autorisationStatusProperties: Record<
  AutorisationStatus,
  AutorisationStatusProperties
> = {
  [AutorisationStatus.AUTHORIZED]: {
    label: "authorizations.myAutorisations.status.AUTHORIZED",
    variant: "success",
  },
  [AutorisationStatus.BLOCKED]: {
    label: "authorizations.myAutorisations.status.BLOCKED",
    variant: "danger",
  },
} as const;

export const AUTORISATIONS_STATUS = Object.keys(AutorisationStatus)
  .map((key) => key as AutorisationStatus)
  .map((key) => ({
    value: key,
    label: autorisationStatusProperties[key].label,
  })) as OptionType[];
