import { ReactNode, FunctionComponent } from "react";
import { Checkbox, Form } from "antd";
import { useTranslation } from "react-i18next";
import { InternalNamePath } from "rc-field-form/lib/interface";
import { Rule } from "antd/es/form";
import { formHelper } from "@utils/helpers/form-helper";
import FormFieldLabel from "@components/core/labels/FormFieldLabel";
import { CheckboxChangeEvent } from "antd/es/checkbox";

interface PropsType {
  module: string;
  field: string | InternalNamePath;
  mustBeChecked?: boolean;
  emptyErrorMessage?: string;
  readOnly?: boolean;
  onChange?: (e: CheckboxChangeEvent) => void;
  fieldPrefix?: string;
  label?: string;
  className?: string;
  rules?: Rule[];
  disabled?: boolean;
  initialValue?: boolean;
  showTooltip?: boolean;
  labelTooltip?: ReactNode | string;
}

const CheckboxFormField: FunctionComponent<PropsType> = (props: PropsType) => {
  const {
    module,
    field,
    showTooltip,
    labelTooltip,
    mustBeChecked,
    readOnly,
    emptyErrorMessage,
    onChange,
    fieldPrefix = "",
    label,
    className = "",
    rules = [],
    disabled,
    initialValue,
  } = props;
  const { t } = useTranslation();

  const isRequired: boolean = readOnly ? false : !!mustBeChecked;

  const i18nField = formHelper.getI18nFieldFromField(field);

  const key = `field_${i18nField}`.replace(",", "_");

  const inputLabel = label ? label : t(`${module}.fields.${i18nField}`);

  return (
    <>
      <Form.Item
        name={fieldPrefix ? `${fieldPrefix}${field.toString()}` : field}
        key={fieldPrefix + key}
        valuePropName="checked"
        rules={
          isRequired
            ? [
                {
                  validator: (_, value) =>
                    value
                      ? Promise.resolve()
                      : Promise.reject(
                          new Error(
                            formHelper.getErrorMessage(emptyErrorMessage),
                          ),
                        ),
                },
                ...rules,
              ]
            : [...rules]
        }
        className={className}
        initialValue={initialValue}
      >
        <Checkbox
          disabled={readOnly ?? disabled}
          className={readOnly ? " readonly-form-field" : ""}
          id={fieldPrefix + key}
          onChange={onChange}
          data-test={field}
        >
          <span
            data-test="input-label"
            data-alt={inputLabel}
            data-test-for={field}
          >
            <FormFieldLabel
              field={field}
              module={module}
              label={inputLabel}
              showTooltip={showTooltip}
              tooltipLabel={labelTooltip}
            />
          </span>
        </Checkbox>
      </Form.Item>
    </>
  );
};

export default CheckboxFormField;
