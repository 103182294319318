import { setUser } from "@state/auth/AuthEvents";
import { AuthenticationContext } from "@type/core/auth/auth.types";
import { LANGUAGES } from "@utils/enums/language.enum";
import { i18nHelper } from "@utils/helpers/i18n-helper";
import { AuthenticatedUserResponseDto } from "@state/auth/dto/response/authenticated.user.response.dto";

const setAuthenticatedUser = (
  user: AuthenticatedUserResponseDto,
  authContext: AuthenticationContext | null,
): void => {
  if (!authContext || user.id !== authContext.user?.id) {
    setUser(user);

    const language = LANGUAGES.find((l) => l.type === user.language)?.code;
    i18nHelper.changeLanguage(language);
  }
};

export const authHelper = {
  setAuthenticatedUser,
};
