import * as t from "io-ts";

export const licenseManagedResponseDtoDecoderContent = {
  id: t.string,
  key: t.string,
  durationUnit: t.string,
  durationValue: t.number,
  status: t.string,
  statusDate: t.string,
  ordinalNumber: t.union([t.string, t.null]),
  holderName: t.union([t.string, t.null]),
  activationDate: t.union([t.string, t.null]),
  expirationDate: t.union([t.string, t.null]),
};

export const licenseManagedResponseDtoDecoder = t.type(
  licenseManagedResponseDtoDecoderContent,
);

export type LicenseManagedResponseDto = t.TypeOf<
  typeof licenseManagedResponseDtoDecoder
>;
