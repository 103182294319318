import {
  ChangeEvent,
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import PageLayout from "@components/core/layouts/PageLayout";
import { ROUTES } from "@routes/Routes";
import Section from "@components/core/section/Section";
import BasicCard from "@components/core/cards/BasicCard";
import parse from "html-react-parser";
import BasicButton from "@components/core/buttons/BasicButton";
import IconTransfer from "@static/core/img/icon-transfer.svg?react";
import BasicTable from "@components/core/tables/BasicTable";
import { columns as myLicenseColumns } from "@views/core/admin/licenses/columns/MyLicenseColumns";
import { columns as myAutorisationsColumns } from "@views/core/admin/licenses/columns/MyAutorisationsColumns";
import {
  columns as licensesManagementColumns,
  computeOrdinalNumberField,
} from "@views/core/admin/licenses/columns/LicensesManagementColumns";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { AnyObject } from "antd/es/_util/type";
import { useForm } from "antd/lib/form/Form";
import { Form, Spin } from "antd";
import { formValidateTriggers } from "@utils/Constant";
import InputFormField from "@components/core/inputs/InputFormField";
import { toastError, toastSuccess } from "@utils/helpers/toast-helper";
import {
  requestAttributeLicense,
  requestGetHeldLicense,
  requestGetManagedLicenses,
  requestImportLicenseByFile,
  requestImportLicenseByKey,
  requestRejectLicense,
  requestRemoveLicense,
} from "@state/licenses/LicensesEffects";
import { Pageable } from "@type/core/list/pagination.types";
import {
  requestGetUserAuthorizations,
  requestAuthorizeVeterinarySoftware,
  requestBlockVeterinarySoftware,
} from "@state/authorization/AuthorizationEffects";
import { RcFile } from "antd/lib/upload";
import i18n from "i18next";
import { LicenseManagedResponseDto } from "@state/licenses/dto/response/license.managed.response.dto";
import { LicenseHeldResponseDto } from "@state/licenses/dto/response/license.held.response.dto";
import { AuthorizationDetailResponseDto } from "@state/authorization/response/authorization.detail.response.dto";

const LicensesScreen: FunctionComponent = () => {
  const spinIcon = <LoadingOutlined spin />;
  const { t } = useTranslation();
  const [form] = useForm();
  const [licenses, setLicenses] = useState<LicenseManagedResponseDto[]>([]);
  const [heldLicense, setHeldLicense] = useState<LicenseHeldResponseDto[]>([]);
  const [authorizationPage, setAuthorizationPage] = useState<number>(1);
  const [areUserAuthorizationsRetrieved, setAreUserAuthorizationsRetrieved] =
    useState<boolean>(false);
  const [myAuthorizations, setMyAuthorizations] = useState<
    Pageable<AuthorizationDetailResponseDto>
  >({
    size: 10,
    totalElements: 0,
    content: [],
    numberOfElements: 0,
    totalPages: 0,
    number: 0,
  });

  useEffect(() => {
    void requestGetUserAuthorizations({
      page: authorizationPage - 1,
      limit: 10,
    });
    setAreUserAuthorizationsRetrieved(false);
  }, []);

  useEffect(() => {
    return requestGetUserAuthorizations.done.watch(({ result }) => {
      if (result.ok && result.data) {
        setMyAuthorizations(result.data);
        setAreUserAuthorizationsRetrieved(true);
      }
    });
  }, []);

  useEffect(() => {
    return requestBlockVeterinarySoftware.done.watch(() => {
      void requestGetUserAuthorizations({
        page: authorizationPage - 1,
        limit: 10,
      });
      setAreUserAuthorizationsRetrieved(true);
    });
  }, []);

  useEffect(() => {
    return requestAuthorizeVeterinarySoftware.done.watch(() => {
      void requestGetUserAuthorizations({
        page: authorizationPage - 1,
        limit: 10,
      });
      setAreUserAuthorizationsRetrieved(true);
    });
  }, []);

  useEffect(() => {
    return requestImportLicenseByKey.done.watch(({ result }) => {
      if (result.ok && result.data) {
        setLicenses(result.data);
        form.setFieldValue("licenseNumber", "");
      } else {
        toastError(
          t("licenses.errors.import.key", {
            details: result.errorMessage ? result.errorMessage : "",
          }),
        );
      }
    });
  });

  const attributeLicense = useCallback(
    (licenseId: string, ordinalNumber: string) => {
      requestAttributeLicense({
        upperEntityId: licenseId + "/assign/" + ordinalNumber,
      }).catch(() => {
        toastError(t("licenses.errors.attribute.notFound"));
      });
    },
    [],
  );

  const removeLicense = useCallback(
    (licenseId: string, ordinalNumber: string) => {
      requestRemoveLicense({
        upperEntityId: licenseId + "/remove/" + ordinalNumber,
      }).catch(() => {
        toastError(t("licenses.errors.remove"));
      });
    },
    [],
  );

  const importLicenseByKey = useCallback(() => {
    const key = form.getFieldValue("licenseNumber") as string;
    void requestImportLicenseByKey({
      dto: {
        key: key,
      },
    })
      .catch(() => {
        toastError(t("licenses.errors.import.key"));
      })
      .then(() => {
        toastSuccess(t("licenses.success.import"));
      });
  }, []);

  const importLicenseByFile = useCallback((formData: FormData) => {
    void requestImportLicenseByFile({
      dto: formData,
    })
      .catch(() => {
        toastError(t("licenses.errors.import.file"));
      })
      .then(() => {
        toastSuccess(t("licenses.success.import-by-file"));
      });
  }, []);

  useEffect(() => {
    return requestGetManagedLicenses.done.watch(({ result }) => {
      if (result.ok && result.data) {
        setLicenses(result.data);
      } else {
        toastError(
          t("licenses.errors.get-managed", {
            details: result.errorMessage ? result.errorMessage : "",
          }),
        );
      }
    });
  });

  useEffect(() => {
    return requestGetHeldLicense.done.watch(({ result }) => {
      if (result.ok && result.data) {
        setHeldLicense([result.data]);
      } else if (result.responseCode != 409) {
        toastError(
          t("licenses.errors.get-held", {
            details: result.errorMessage ? result.errorMessage : "",
          }),
        );
      } else {
        setHeldLicense([]);
      }
    });
  });

  useEffect(() => {
    return requestAttributeLicense.done.watch(({ result }) => {
      if (result.ok && result.data) {
        const modifiedLicenses = licenses.map((license) => {
          if (license.key === result.data?.key) {
            return result.data;
          }
          return license;
        });

        setLicenses(modifiedLicenses);
        form.setFieldValue(computeOrdinalNumberField(result.data.id), "");
        fetchHeldLicense();
      } else {
        toastError(
          t("licenses.errors.attribute.notFound", {
            details: result.errorMessage ? result.errorMessage : "",
          }),
        );
      }
    });
  });

  useEffect(() => {
    return requestRejectLicense.done.watch(() => {
      fetchHeldLicense();
      fetchManagedLicenses();
    });
  }, []);

  useEffect(() => {
    return requestRemoveLicense.done.watch(({ result }) => {
      if (result.ok && result.data) {
        const modifiedLicenses = licenses.map((license) => {
          if (license.key === result.data?.key) {
            return result.data;
          }
          return license;
        });

        setLicenses(modifiedLicenses);
        fetchHeldLicense();
      } else {
        toastError(
          t("licenses.errors.remove", {
            details: result.errorMessage ? result.errorMessage : "",
          }),
        );
      }
    });
  });

  useEffect(() => {
    return requestImportLicenseByFile.done.watch(({ result }) => {
      if (result.ok && result.data) {
        setLicenses(result.data);
      } else {
        toastError(
          t("licenses.errors.import.file", {
            details: result.errorMessage ? result.errorMessage : "",
          }),
        );
      }
    });
  });

  const fetchManagedLicenses = useCallback(() => {
    requestGetManagedLicenses({}).catch(() => {
      toastError(t("licenses.errors.get-managed"));
    });
  }, []);

  const fetchHeldLicense = useCallback(() => {
    requestGetHeldLicense({ id: "" }).catch(() => {
      toastError(t("licenses.errors.get-held"));
    });
  }, []);

  useEffect(() => {
    fetchManagedLicenses();
    fetchHeldLicense();
  }, []);

  const handleSubmit = () => {
    if (form.getFieldValue("licenseNumber") != "") {
      importLicenseByKey();
    } else {
      toastError(t("licenses.errors.import.emptyKey"));
    }
  };

  const handleAuthorizationPageChange = (pageNumber: number) => {
    setAuthorizationPage(pageNumber);
  };

  const handleFileSubmit = (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files != null && files.length > 0) {
      const file = files[0];

      const reader = new FileReader();
      reader.onload = (event) => {
        const fileContentArray = new Uint8Array(
          event.target?.result as ArrayBuffer,
        );

        const fileBlob = new Blob([fileContentArray], {
          type: file.type,
        });

        const formData = new FormData();
        formData.append("file-data", fileBlob, file.name);
        importLicenseByFile(formData);
      };
      reader.readAsArrayBuffer(file as RcFile);
    }
  };

  const importButtonClickHandler = () => {
    const input = document.getElementById("fileInput");
    if (input != null) {
      input.click();
    }
  };

  const handleAttributeLicense = (licenseId: string) => {
    const ordinalNumber = form.getFieldValue(
      `ordinalNumber ${licenseId}`,
    ) as string;
    const ordinalNumberRegex = /^[a-zA-Z0-9]{1,20}$/;
    if (!RegExp(ordinalNumberRegex).exec(ordinalNumber)) {
      toastError(i18n.t("licenses.errors.attribute.invalidOrdinalNumber"));
    } else {
      attributeLicense(licenseId, ordinalNumber);
    }
  };

  const handleRemoveLicense = (licenseId: string, ordinalNumber: string) => {
    removeLicense(licenseId, ordinalNumber);
  };

  return (
    <PageLayout
      container
      title={t("licenses.title")}
      returnRoute={ROUTES.connected.home.generate()}
      returnText={t("dashboard.return")}
    >
      <>
        <Section title={t("licenses.myLicense.title")}>
          <BasicTable
            dataSource={heldLicense}
            columns={myLicenseColumns()}
            rowClassName={(record: AnyObject) =>
              record.startPeriod && record.endPeriod
                ? new Date() > new Date(record.endPeriod as string)
                  ? "row-danger"
                  : ""
                : "row-warning"
            }
          />
        </Section>
        <Section
          title={t("authorizations.myAutorisations.title")}
          description={t("authorizations.myAutorisations.description")}
        >
          {!areUserAuthorizationsRetrieved ? (
            <div className="text-center">
              <Spin indicator={spinIcon} />
            </div>
          ) : (
            <BasicTable
              dataSource={myAuthorizations.content}
              columns={myAutorisationsColumns()}
              pagination={{
                total: myAuthorizations.totalElements,
                pageSize: myAuthorizations.size,
                current: myAuthorizations.number + 1,
                onChange: (pageNumber: number) => {
                  handleAuthorizationPageChange(pageNumber);
                },
              }}
            />
          )}
        </Section>
        <Section
          title={t("licenses.licensesManagement.title")}
          rightContent={
            <>
              <BasicButton
                size="sm"
                variant="primary-outlined"
                icon={<PlusOutlined />}
                iconRight
                onClick={() => {
                  importButtonClickHandler();
                }}
                text={t("licenses.licensesManagement.import")}
              />
              <input
                id="fileInput"
                type="file"
                name="fileInput"
                hidden
                onChange={(e) => {
                  handleFileSubmit(e);
                }}
                accept=".csv,.xlsx,.xls"
              />
            </>
          }
        >
          <Form
            {...formValidateTriggers}
            form={form}
            layout="vertical"
            className="table-licenses-management"
          >
            <BasicTable
              dataSource={licenses}
              columns={licensesManagementColumns(
                form,
                handleAttributeLicense,
                handleRemoveLicense,
              )}
              rowClassName={(record: AnyObject) =>
                record.activationDate && record.expirationDate
                  ? new Date() > new Date(record.expirationDate as string)
                    ? "row-danger"
                    : ""
                  : "row-warning"
              }
            />
            <div className="table-licenses-management-add-row">
              <InputFormField
                form={form}
                module="licenses.form"
                field="licenseNumber"
                className="mb-0"
              />
              <BasicButton
                size="sm"
                variant="primary-outlined"
                onClick={() => {
                  handleSubmit();
                }}
                icon={<PlusOutlined />}
              />
            </div>
          </Form>
        </Section>
        <BasicCard className="mt-48">
          <div className="d-flex align-items-center justify-content-end flex-wrap gap-16">
            <p className="flex-grow-1 m-0">
              {parse(t("licenses.licensesManagement.card.situationChange"))}
            </p>
            <BasicButton
              size="sm"
              variant="danger"
              icon={<IconTransfer />}
              iconRight
              text={t("licenses.licensesManagement.card.transferManagement")}
              disabled={true}
            />
          </div>
        </BasicCard>
      </>
    </PageLayout>
  );
};

export default LicensesScreen;
