import { ReactNode, JSX } from "react";
import { useTranslation } from "react-i18next";
import BasicButton from "@components/core/buttons/BasicButton";

interface Props {
  content: string | ReactNode;
  type?: "danger" | "warning" | "info";
  onClose: () => void;
}

const BasicBanner = (props: Props): JSX.Element => {
  const { content, type = "danger", onClose } = props;
  const { t } = useTranslation();
  return (
    <div id="banner" className={`alert-${type} banner`}>
      <div className="content-contained content-lg">
        <i className="fa fa-times close" onClick={onClose} />
        <div className="d-flex align-items-center justify-content-between flex-wrap">
          <p className="my-8">{content}</p>
          <BasicButton
            className="my-8"
            text={t("banners.buttons.ok")}
            onClick={onClose}
          />
        </div>
      </div>
    </div>
  );
};

export default BasicBanner;
