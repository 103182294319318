import { useCallback, useEffect, useMemo, useRef } from "react";
import { useUnit } from "effector-react";
import { authenticationStore } from "@state/auth/AuthStore";
import { preferenceStore } from "@state/preferences/PreferenceStore";
import {
  requestCreateOrUpdatePreference,
  requestGetUserPreferences,
} from "@state/preferences/PreferenceEffects";
import { putPreference } from "@state/preferences/PreferenceEvents";
import { PreferenceContext } from "@/type/core/preferences/preference.types";
import { PreferenceTypeEnum } from "@/utils/enums/preferences.enum";

interface UsePreferencesReturn {
  isLoading: boolean;
  preferences: PreferenceContext;
  updatePreference: (
    preferenceType: PreferenceTypeEnum,
    value: string,
    forceDeletion: boolean,
  ) => void;
  sanitizePreference: (
    preferenceType: PreferenceTypeEnum,
    preferenceList: string[],
    preference?: string,
  ) => void;
  isConfigurationOk: boolean;
}

export const usePreferences = (): UsePreferencesReturn => {
  const updateCallStack = useRef<Partial<Record<PreferenceTypeEnum, string>>>(
    {},
  );

  const authenticationContext = useUnit(authenticationStore);
  const preferenceContext = useUnit(preferenceStore);

  const isConfigurationOk = useMemo(
    () => preferenceContext.preferences.SELECTED_PROFILE != null,
    [preferenceContext.preferences],
  );

  const fetchPreferences = useCallback(() => {
    void requestGetUserPreferences({});
  }, []);

  useEffect(() => {
    if (
      preferenceContext.isLoading &&
      authenticationContext?.user &&
      !isConfigurationOk
    ) {
      fetchPreferences();
    }
  }, [
    authenticationContext?.user,
    fetchPreferences,
    isConfigurationOk,
    preferenceContext.isLoading,
  ]);

  useEffect(() => {
    return requestGetUserPreferences.done.watch(({ result }) => {
      if (result.ok && result.data) {
        const localPreferences: PreferenceContext = result.data.reduce(
          (prev, curr) => ({ ...prev, [curr.preferenceType]: curr.value }),
          {},
        );

        putPreference(localPreferences);
      }
    });
  });

  const updatePreference = useCallback(
    (
      preferenceType: PreferenceTypeEnum,
      value: string,
      forceDeletion: boolean,
    ) => {
      const previousUpdateCall = updateCallStack.current[preferenceType];
      if (previousUpdateCall) {
        if (previousUpdateCall !== value) {
          setTimeout(() => {
            updatePreference(preferenceType, value, forceDeletion);
          }, 500);
        }

        return;
      }

      updateCallStack.current[preferenceType] = value;

      void requestCreateOrUpdatePreference({
        dto: { preferenceType, value, forceDeletion },
      });
      putPreference({ [preferenceType]: value });
    },
    [],
  );

  useEffect(
    () =>
      requestCreateOrUpdatePreference.done.watch(({ params: { dto } }) => {
        if (dto?.preferenceType) {
          updateCallStack.current[dto.preferenceType] = undefined;
        }
      }),
    [],
  );

  const sanitizePreference = useCallback(
    (
      preferenceType: PreferenceTypeEnum,
      preferenceList: string[],
      preference?: string,
    ) => {
      if (preference && !preferenceList.includes(preference)) {
        updatePreference(preferenceType, "no-preference", true);
        putPreference({ [preferenceType]: null });
      } else if (
        preferenceList.length === 1 &&
        preferenceList[0] !== preference
      ) {
        updatePreference(preferenceType, preferenceList[0], false);
      }
    },
    [updatePreference],
  );

  return {
    isLoading: preferenceContext.isLoading,
    preferences: preferenceContext.preferences,
    updatePreference,
    sanitizePreference,
    isConfigurationOk,
  };
};
