import * as t from "io-ts";

export const authorizationItemResponseDtoDecoderContent = {
  requesterName: t.string,
  requesterExternalReference: t.string,
  status: t.string,
  statusDate: t.string,
};

export const authorizationItemResponseDtoDecoder = t.type(
  authorizationItemResponseDtoDecoderContent,
);

export type AuthorizationItemResponseDto = t.TypeOf<
  typeof authorizationItemResponseDtoDecoder
>;
