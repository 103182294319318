import i18n from "i18next";

export enum TransactionRoleEnum {
  CREATOR = "CREATOR",
  RECIPIENT = "RECIPIENT",
}

export interface TransactionRoleProperties {
  id: string;
  label: string;
  variant: string;
}

export const transactionRoleProperties: Record<
  string,
  TransactionRoleProperties
> = {
  [TransactionRoleEnum.CREATOR]: {
    id: TransactionRoleEnum.CREATOR,
    label: i18n.t("transactions.role.CREATOR.label"),
    variant: "warning",
  },
  [TransactionRoleEnum.RECIPIENT]: {
    id: TransactionRoleEnum.RECIPIENT,
    label: i18n.t("transactions.role.RECIPIENT.label"),
    variant: "warning",
  },
} as const;
