import { FunctionComponent, JSX, useEffect, useState } from "react";
import { FormInstance } from "antd";
import { InternalNamePath } from "rc-field-form/lib/interface";
import { useWatch } from "antd/es/form/Form";
import { AnyObject } from "antd/es/_util/type";

interface FloatingLabelProps {
  label: string | JSX.Element;
  field: string | InternalNamePath;
  form: FormInstance;
  children: string | JSX.Element;
  forceLabelRerender?: boolean;
  className?: string;
}

const FloatingLabel: FunctionComponent<FloatingLabelProps> = (
  props: FloatingLabelProps,
) => {
  const {
    label,
    field,
    form,
    children,
    forceLabelRerender,
    className = "",
  } = props;
  const value = useWatch(field, form) as AnyObject | null;
  const [isFocus, setIsFocus] = useState<boolean>(false);
  const [hasValue, setHasValue] = useState<boolean>(!!value);

  useEffect(() => {
    let newHasValue = false;
    if (typeof value === "undefined" || value === null) {
      newHasValue = !!value;
    } else {
      newHasValue = (value as string[]).length !== 0;
    }
    setHasValue(newHasValue);
  }, [value, forceLabelRerender]);

  return (
    <div
      className={`floating-label-container ${isFocus || hasValue ? "is-floating" : ""} ${className}`}
      onBlur={() => {
        setIsFocus(false);
      }}
      onFocus={() => {
        setIsFocus(true);
      }}
    >
      {children}
      <label className="floating-label">{label}</label>
    </div>
  );
};

export default FloatingLabel;
