import { ROUTES } from "@routes/Routes";
import { IdcardOutlined, LogoutOutlined } from "@ant-design/icons";
import { FeatureItem } from "@type/core/feature/feature.types";
import { logout } from "@state/auth/AuthEvents";
import { toastInfo } from "@utils/helpers/toast-helper";
import i18n from "../i18n/i18n";

{
  /* TODO: LINK -> correct urls */
}
export const featureItems: Record<string, FeatureItem> = {
  licenses: {
    key: "licenses",
    link: ROUTES.connected.licenses.generate(),
    text: "features.items.licenses",
  },
  profil: {
    key: "profil",
    text: "features.items.profil",
    onClick: () => {
      toastInfo(i18n.t("features.message.missing-feature"));
    },
    link: ROUTES.connected.home.generate(),
  },
  account: {
    key: "account",
    text: "features.items.account",
    children: [
      {
        key: "infos",
        icon: <IdcardOutlined />,
        link: ROUTES.connected.account.infos.generate(),
        text: "features.items.infos",
      },
      {
        key: "logout",
        icon: <LogoutOutlined />,
        link: ROUTES.connected.home.generate(),
        text: "features.items.logout",
        onClick: () => {
          logout();
        },
      },
    ],
  },
};
