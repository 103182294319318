import * as t from "io-ts";
import {
  TransactionRoleEnumV,
  TransactionStatusEnumV,
} from "@type/core/transactions/transaction.types";
import { DocumentItem } from "@state/transactions/dto/response/transaction.document.item.dto";

export const transactionItemResponseDtoDecoderContent = {
  transactionId: t.string,
  transactionLabel: t.string,
  depositedOn: t.string,
  submittedBy: t.union([t.string, t.null]),
  status: TransactionStatusEnumV,
  role: TransactionRoleEnumV,
  signDate: t.union([t.string, t.null]),
  expirationDate: t.union([t.string, t.null]),
  expired: t.boolean,
  documents: t.array(DocumentItem),
};

export const transactionItemResponseDtoDecoder = t.type(
  transactionItemResponseDtoDecoderContent,
);

export type TransactionItemResponseDto = t.TypeOf<
  typeof transactionItemResponseDtoDecoder
>;
