import { createEffect } from "effector";
import {
  restDetailsHandler,
  restGetUniqueHandler,
  restPostHandler,
} from "@utils/rest/RestClient";
import configuration from "@/utils/Config";
import { ResetPasswordRequestDto } from "@state/auth/dto/request/reset.password.request.dto";
import { ValidateTokenRequestDto } from "@state/auth/dto/request/validate.token.request.dto";
import { ForgotPasswordRequestDto } from "@state/auth/dto/request/forgot.password.request.dto";
import { LoginRequestDto } from "@state/auth/dto/request/login.request.dto";
import { RegistrationRequestDto } from "@state/auth/dto/request/registration.request.dto";
import {
  PasswordStrengthConstraintsDetailsResponseDto,
  passwordStrengthConstraintsDetailsResponseDtoDecoder,
  passwordStrengthConstraintsDetailsResponseDtoDecoderContent,
} from "@state/auth/dto/response/password.strength.constraints.details.response.dto";
import { OtpRequestDto } from "@state/auth/dto/request/otp.request.dto";
import { ULinkRequestDto } from "@state/auth/dto/request/ulink.request.dto";
import { UpdatePasswordRequestDto } from "@state/auth/dto/request/update.password.request.dto";
import { ConfirmRegistrationRequestDto } from "@state/auth/dto/request/confirm.registration.request.dto";
import {
  AuthenticatedUserResponseDto,
  authenticatedUserResponseDtoDecoder,
  authenticatedUserResponseDtoDecoderContent,
} from "@state/auth/dto/response/authenticated.user.response.dto";
import {
  AuthResultResponseDto,
  authResultResponseDtoDecoder,
  authResultResponseDtoDecoderContent,
} from "@state/auth/dto/response/auth.result.response.dto";

export const buildSsoLoginUrl = (redirectUrl: string) => {
  console.log(configuration.backendBaseUrl);
  return `${configuration.backendBaseUrl}/auth/sso/authorize/web?callback=${redirectUrl}`;
};

export const requestGetUserInfo = createEffect({
  handler: restGetUniqueHandler<
    AuthenticatedUserResponseDto,
    Record<string, unknown>,
    typeof authenticatedUserResponseDtoDecoderContent,
    typeof authenticatedUserResponseDtoDecoder
  >(
    configuration.backendBaseUrl + "/account/me",
    undefined,
    authenticatedUserResponseDtoDecoder,
  ),
});

export const requestForgotPassword = createEffect({
  handler: restPostHandler<ForgotPasswordRequestDto>(
    configuration.backendBaseUrl + "/account/forgot-password",
  ),
});
export const requestRecoverPassword = createEffect({
  handler: restPostHandler<ResetPasswordRequestDto>(
    configuration.backendBaseUrl + "/account/recover-password",
  ),
});
export const requestUpdatePassword = createEffect({
  handler: restPostHandler<UpdatePasswordRequestDto>(
    configuration.backendBaseUrl + "/users/update-password",
  ),
});

export const requestValidateResetToken = createEffect({
  handler: restPostHandler<
    ValidateTokenRequestDto,
    PasswordStrengthConstraintsDetailsResponseDto,
    typeof passwordStrengthConstraintsDetailsResponseDtoDecoderContent,
    typeof passwordStrengthConstraintsDetailsResponseDtoDecoder
  >(
    configuration.backendBaseUrl + "/account/validate-reset-token",
    undefined,
    passwordStrengthConstraintsDetailsResponseDtoDecoder,
  ),
});

export const requestGetPasswordStrengthConstraints = createEffect({
  handler: restDetailsHandler<
    PasswordStrengthConstraintsDetailsResponseDto,
    typeof passwordStrengthConstraintsDetailsResponseDtoDecoderContent,
    typeof passwordStrengthConstraintsDetailsResponseDtoDecoder
  >(
    configuration.backendBaseUrl + "/account/password-strength-constraints",
    undefined,
    passwordStrengthConstraintsDetailsResponseDtoDecoder,
  ),
});

export const requestLogin = createEffect({
  handler: restPostHandler<
    LoginRequestDto,
    AuthResultResponseDto,
    typeof authResultResponseDtoDecoderContent,
    typeof authResultResponseDtoDecoder
  >(
    configuration.backendBaseUrl + "/auth/login/web",
    undefined,
    authResultResponseDtoDecoder,
  ),
});

export const requestOtp = createEffect({
  handler: restPostHandler<
    OtpRequestDto,
    AuthResultResponseDto,
    typeof authResultResponseDtoDecoderContent,
    typeof authResultResponseDtoDecoder
  >(
    configuration.backendBaseUrl + "/auth/otp",
    undefined,
    authResultResponseDtoDecoder,
  ),
});

export const requestRegistration = createEffect({
  handler: restPostHandler<RegistrationRequestDto>(
    `${configuration.backendBaseUrl}/auth/registration`,
  ),
});

export const requestConfirmRegistration = createEffect({
  handler: restPostHandler<ConfirmRegistrationRequestDto>(
    `${configuration.backendBaseUrl}/auth/registration/confirm`,
  ),
});

export const requestLogout = createEffect({
  handler: restPostHandler(configuration.backendBaseUrl + "/auth/logout/web"),
});

export const requestULink = createEffect({
  handler: restPostHandler<ULinkRequestDto, AuthResultResponseDto>(
    configuration.backendBaseUrl + "/auth/u-link",
  ),
});
