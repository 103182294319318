import { DateTime } from "luxon";

class DateFormat {
  standardDate(date: Date): string {
    const browserTimezone = DateTime.local().zone;
    return DateTime.fromJSDate(date)
      .setZone(browserTimezone)
      .toFormat("dd/MM/yyyy à HH'h'mm");
  }

  completeDate(date: Date): string {
    const browserTimezone = DateTime.local().zone;
    return DateTime.fromJSDate(date)
      .setZone(browserTimezone)
      .toFormat("dd/MM/yyyy à HH'h'mm:ss");
  }

  dashboardDate(date: Date): string {
    const browserTimezone = DateTime.local().zone;
    return DateTime.fromJSDate(date)
      .setZone(browserTimezone)
      .toFormat("dd/MM/yyyy");
  }
}

export default new DateFormat();
