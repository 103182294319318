import { ColumnsType } from "antd/lib/table/interface";
import { AnyObject } from "antd/es/_util/type";
import i18n from "i18next";
import DateFormat from "@utils/DateFormat";

export const columns = (): ColumnsType<AnyObject> => [
  {
    title: i18n.t("devices.edit.table.fields.name"),
    dataIndex: "name",
  },
  {
    title: i18n.t("devices.edit.table.fields.statusDate"),
    dataIndex: "statusDate",
    render: (lastDate: string) => {
      return DateFormat.dashboardDate(new Date(lastDate));
    },
  },
];
