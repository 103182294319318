import * as t from "io-ts";
import { TransactionStatusEnum } from "@utils/enums/transactions/transaction.status.enum";
import { TransactionRoleEnum } from "@utils/enums/transactions/transaction.role.enum";

export const TransactionStatusEnumV = t.keyof(
  Object.fromEntries(
    Object.keys(TransactionStatusEnum).map((p) => [p, null]),
  ) as Record<TransactionStatusEnum, null>,
);

export const TransactionRoleEnumV = t.keyof(
  Object.fromEntries(
    Object.keys(TransactionRoleEnum).map((p) => [p, null]),
  ) as Record<TransactionRoleEnum, null>,
);
