import {
  Dispatch,
  FunctionComponent,
  ReactElement,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import { Document, Page, pdfjs } from "react-pdf";
import {
  ArrowsAltOutlined,
  DownloadOutlined,
  LeftOutlined,
  LoadingOutlined,
  RedoOutlined,
  RightOutlined,
  UndoOutlined,
} from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { NumberFormat } from "@utils/NumberFormat";
import BasicButton from "@components/core/buttons/BasicButton";
import DisplayDocumentModal from "@views/core/document/DisplayDocumentModal";

interface Props {
  mimeType?: string;
  id?: string;
  filename?: string;
  height?: number;
  width?: number;
  pdfError?: boolean;
  data?: string;
  showControls?: boolean;
  setPdfError?: Dispatch<SetStateAction<boolean>>;
  rotateProps?: number;
  className?: string;
}

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  "pdfjs-dist/build/pdf.worker.min.mjs",
  import.meta.url,
).toString();

const DisplayDocument: FunctionComponent<Props> = ({
  id,
  filename,
  mimeType,
  height,
  width,
  data,
  showControls,
  pdfError,
  setPdfError,
  rotateProps = 0,
  className = "",
}): ReactElement => {
  const { t } = useTranslation();

  const [rotate, setRotate] = useState<number>(rotateProps);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [loading] = useState<boolean>(false);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [numPages, setNumPages] = useState<number>();

  useEffect(() => {
    setRotate(rotateProps);
  }, [rotateProps]);

  useEffect(() => {
    setRotate(rotateProps);
  }, [id, rotateProps]);

  const onLeftClick = () => {
    if (pageNumber !== 1) {
      setPageNumber((state: number): number => state - 1);
    }
  };

  const onRightClick = () => {
    if (pageNumber !== numPages) {
      setPageNumber((state: number): number => state + 1);
    }
  };

  const downloadDocument = () => {
    if (filename && data) {
      const downloadLink = window.document.createElement("a");
      downloadLink.href = `data:${mimeType};base64,${data}`;
      downloadLink.download = filename;
      downloadLink.click();
    }
  };

  const spinIcon = <LoadingOutlined spin />;

  const controls = (): ReactElement => (
    <div className="DisplayButtons">
      <BasicButton
        className="mb-3"
        icon={<RedoOutlined />}
        onClick={() => {
          setRotate((state: number) => NumberFormat.mod(state + 1, 4));
        }}
      />
      <BasicButton
        className="mb-3"
        icon={<UndoOutlined />}
        onClick={() => {
          setRotate((state: number) => NumberFormat.mod(state - 1, 4));
        }}
      />
      <BasicButton
        className="mb-3"
        icon={<ArrowsAltOutlined />}
        onClick={() => {
          setShowModal(true);
        }}
      />
      <BasicButton
        icon={<DownloadOutlined />}
        onClick={downloadDocument}
        disabled={loading}
      />

      <DisplayDocumentModal
        visible={showModal}
        onCancel={() => {
          setShowModal(false);
        }}
        mimeType={mimeType ?? "application/pdf"}
        data={data}
        rotate={rotate}
      />
    </div>
  );

  if (data) {
    if (mimeType === "application/pdf") {
      return (
        <div
          className={`Display d-flex justify-content-center align-items-center ${className}`}
        >
          {numPages && numPages > 1 && (
            <LeftOutlined
              className={`m-0 ${
                pageNumber <= 1 ? "DocumentPageNavDisabled" : "DocumentPageNav"
              }`}
              onClick={onLeftClick}
            />
          )}
          <Document
            className="d-flex justify-content-center"
            file={`data:application/pdf;base64,${data}`}
            error={t("forms.folder.form.DOCUMENTS.documents.errorPdf")}
            onLoadSuccess={(result) => {
              setPdfError?.(false);
              setNumPages(result.numPages);
              setPageNumber(1);
            }}
          >
            <Page
              height={height}
              width={width}
              pageNumber={pageNumber}
              rotate={rotate * 90}
              renderTextLayer={false}
              renderAnnotationLayer={false}
            />
          </Document>
          {numPages && numPages > 1 && (
            <RightOutlined
              className={`m-0  ${
                !!numPages && pageNumber >= numPages
                  ? "DocumentPageNavDisabled"
                  : "DocumentPageNav"
              }`}
              onClick={onRightClick}
            />
          )}
          {showControls && !pdfError && controls()}
        </div>
      );
    } else {
      return (
        <div className={`Display Rotate-${rotate} ${className}`}>
          <img
            height={[1, 3].includes(rotate) ? "100%" : height}
            width={[1, 3].includes(rotate) ? height : "100%"}
            alt="Images"
            src={`data:image/png;base64,${data}`}
          />
          {showControls && controls()}
        </div>
      );
    }
  } else {
    return (
      <div className="Display d-flex flex-column justify-content-center">
        <div className="text-center d-flex flex-column text-center">
          {spinIcon}
        </div>
      </div>
    );
  }
};

export default DisplayDocument;
