import * as t from "io-ts";

export const licenseHeldResponseDtoDecoderContent = {
  id: t.string,
  key: t.string,
  durationUnit: t.string,
  durationValue: t.number,
  managerName: t.string,
  status: t.string,
  statusDate: t.string,
  activationDate: t.string,
  expirationDate: t.string,
};

export const licenseHeldResponseDtoDecoder = t.type(
  licenseHeldResponseDtoDecoderContent,
);

export type LicenseHeldResponseDto = t.TypeOf<
  typeof licenseHeldResponseDtoDecoder
>;
