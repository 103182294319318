import i18n from "i18next";
import Cookies from "js-cookie";

const changeLanguage = (language: string | undefined): void => {
  if (language) {
    Cookies.set("sv_language", language);
    void i18n.changeLanguage(language);
  }
};

export const i18nHelper = {
  changeLanguage,
};
