import * as t from "io-ts";
import { errorCodeItemResponseDtoDecoder } from "@state/app/response/error.code.item.response.dto";

export const featureErrorCodeItemResponseDtoDecoderContent = {
  feature: t.string,
  errorCodes: t.array(errorCodeItemResponseDtoDecoder),
};

export const featureErrorCodeItemResponseDtoDecoder = t.type(
  featureErrorCodeItemResponseDtoDecoderContent,
);

export type FeatureErrorCodeItemResponseDto = t.TypeOf<
  typeof featureErrorCodeItemResponseDtoDecoder
>;
