import { CALLBACK_URL_PARAM } from "@utils/Constant";
import { ROUTES } from "@routes/Routes";

export interface Query {
  name: string;
  value: string;
}

export function extractStringFrom(
  value: string | string[] | null | undefined,
): string | undefined {
  if (value == null) {
    return undefined;
  }

  if (value instanceof Array) {
    return value[0];
  }

  return value;
}

export function extractNumberFrom(
  value: string | string[] | null | undefined,
): number | undefined {
  if (!value) {
    return undefined;
  }

  if (value instanceof Array) {
    return parseInt(value[0]);
  }

  return parseInt(value) || undefined;
}

export function computeRedirectUrlFrom(value: string): string {
  const callback = new URLSearchParams(value).get(CALLBACK_URL_PARAM);

  if (callback && callback.length > 0) {
    return callback;
  } else {
    return ROUTES.connected.home.generate();
  }
}

export function computePathWithQueries(url: string, queries: Query[]): string {
  if (queries.length === 0) {
    return url;
  }

  const queriesString = queries.reduce(
    (prev, current) => `${prev}${current.name}=${current.value}&`,
    "",
  );

  return `${url}?${queriesString.slice(0, -1)}`;
}
