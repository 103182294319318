import * as t from "io-ts";
import { OtpModeEnumV } from "@type/core/auth/auth.types";

export const connectedUser = {
  email: t.string,
  firstName: t.union([t.string, t.null]),
  lastName: t.union([t.string, t.null]),
};

export const connectedUserDecoder = t.type(connectedUser);

export const authResultResponseDtoDecoderContent = {
  connectedUser: t.union([connectedUserDecoder, t.null]),
  twoFactorMode: t.union([OtpModeEnumV, t.null]),
};

export const authResultResponseDtoDecoder = t.type(
  authResultResponseDtoDecoderContent,
);

export type AuthResultResponseDto = t.TypeOf<
  typeof authResultResponseDtoDecoder
>;
