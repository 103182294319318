import { FunctionComponent } from "react";
import UpdatePasswordForm from "@views/core/admin/account/updatePassword/form/UpdatePasswordForm";
import { useTranslation } from "react-i18next";
import LoginLayout from "@components/core/layouts/LoginLayout";

const UpdatePasswordScreen: FunctionComponent = () => {
  const { t } = useTranslation();

  return (
    <LoginLayout title={t("reset-password.title")}>
      <UpdatePasswordForm />
    </LoginLayout>
  );
};

export default UpdatePasswordScreen;
