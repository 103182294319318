import { FunctionComponent, useEffect, useState } from "react";
import { AppInfosResponseDto } from "@state/app/response/app.infos.response.dto";
import { requestAppInfos } from "@state/app/AppEffects";
import DateFormat from "@utils/DateFormat";
import configuration from "@utils/Config";

const AppInfosRibbon: FunctionComponent = () => {
  const [appInfos, setAppInfos] = useState<AppInfosResponseDto>();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  useEffect(() => {
    void requestAppInfos({});
  }, []);

  useEffect(() => {
    return requestAppInfos.done.watch(({ result }) => {
      if (result.ok && result.data) {
        setAppInfos(result.data);
      }
    });
  });

  const toggleRibbon = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      {appInfos && configuration.appInfosActive && (
        <div
          className={`ribbon ribbon-${isOpen ? "top-left" : "bottom-right"} ${
            appInfos.environment
          }`}
          onClick={toggleRibbon}
        >
          {isOpen ? (
            <span>
              {appInfos.environment}
              <br />
              {appInfos.version}
              <br />
              {appInfos.branch}
              <br />
              {DateFormat.standardDate(new Date(appInfos.commitDate))}
            </span>
          ) : (
            <span>
              {DateFormat.standardDate(new Date(appInfos.commitDate))}
              <br />
              {appInfos.branch}
              <br />
              {appInfos.version}
              <br />
              {appInfos.environment}
            </span>
          )}
        </div>
      )}
    </>
  );
};

export default AppInfosRibbon;
