import { FunctionComponent, ReactElement } from "react";
import { Spin } from "antd";
import Header from "@components/core/headers/Header";
import Footer from "@components/core/footers/Footer";

interface AuthLayoutProps {
  title: string;
  headerClassName?: string;
  bodyClassName?: string;
  noHeader?: boolean;
  noFooter?: boolean;
  children: string | ReactElement;
  isLoading?: boolean;
}

const LoginLayout: FunctionComponent<AuthLayoutProps> = ({
  title,
  headerClassName = "",
  bodyClassName = "",
  noHeader = false,
  noFooter = false,
  children,
  isLoading = false,
}) => {
  return (
    <div className="login-layout">
      <div className="layout-container content-contained content-xxxl">
        {!noHeader && <Header />}
        <main>
          {isLoading ? (
            <div className="h-100 flex-grow-1 d-flex align-items-center justify-content-center">
              <Spin />
            </div>
          ) : (
            <div className="login-content content-contained content-xxl">
              <div className="app-content">
                <div className={`content-header ${headerClassName}`}>
                  <h1 className="text-primary mb-0">{title}</h1>
                </div>
                <div className={`content-body ${bodyClassName}`}>
                  {children}
                </div>
              </div>
              <div className="login-img" />
            </div>
          )}
        </main>
        {!noFooter && <Footer />}
      </div>
    </div>
  );
};

export default LoginLayout;
