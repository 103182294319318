import * as t from "io-ts";
import { FeatureRightEnumV } from "@type/core/endpoint/endpoint.types";

export const featureDetailsResponseDtoDecoderContent = {
  code: t.string,
  rights: t.array(FeatureRightEnumV),
};

export const featureDetailsResponseDtoDecoder = t.type(
  featureDetailsResponseDtoDecoderContent,
);

export type FeatureDetailsResponseDto = t.TypeOf<
  typeof featureDetailsResponseDtoDecoder
>;
