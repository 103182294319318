import * as t from "io-ts";
import configuration from "@utils/Config";
import { createEffect } from "effector";
import { restDetailsHandler, restListHandler } from "@utils/rest/RestClient";
import {
  UserItemResponseDto,
  userItemResponseDtoDecoder,
} from "@state/users/dto/response/user.item.response.dto";

const baseUrl = configuration.backendBaseUrl;
const e2eUrl = baseUrl + "/e2e/";

const userItemResponseDtoArrayDecoder = t.array(userItemResponseDtoDecoder);

export const requestAvailableUsers = createEffect({
  handler: restListHandler<
    UserItemResponseDto,
    Record<string, unknown>,
    typeof userItemResponseDtoDecoder,
    typeof userItemResponseDtoArrayDecoder
  >(`${e2eUrl}users/select`, undefined, userItemResponseDtoArrayDecoder),
});

export const requestSelectUser = createEffect({
  handler: restDetailsHandler(`${e2eUrl}users/select/`),
});
