import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { Spin, Table, TablePaginationConfig } from "antd";
import { ColumnType } from "antd/lib/table/interface";
import { ColumnGroupType, TableRowSelection } from "antd/es/table/interface";
import { AnyObject } from "antd/es/_util/type";

interface BasicTableProps {
  id?: string;
  isLoading?: boolean;
  rowSelection?: TableRowSelection<AnyObject>;
  onRow?: (record: AnyObject) => AnyObject;
  rowClassName?: (record: AnyObject) => string;
  dataSource: readonly AnyObject[];
  columns: (ColumnGroupType<AnyObject> | ColumnType<AnyObject>)[];
  pagination?: false | TablePaginationConfig | undefined;
  className?: string;
}

const BasicTable: FunctionComponent<BasicTableProps> = ({
  id,
  isLoading = false,
  rowSelection,
  onRow,
  rowClassName,
  dataSource,
  columns,
  pagination = false,
  className = "",
}) => {
  const { t } = useTranslation();

  return (
    <Table
      className={`app-table ${className}`}
      id={id}
      locale={{
        emptyText: isLoading
          ? t("table.emptyText.loading")
          : t("table.emptyText.noData"),
        triggerAsc: t("table.sort.asc"),
        triggerDesc: t("table.sort.desc"),
        cancelSort: t("table.sort.cancel"),
      }}
      loading={{ spinning: isLoading, indicator: <Spin /> }}
      rowSelection={rowSelection}
      onRow={onRow}
      pagination={
        pagination
          ? {
              ...pagination,
              showSizeChanger: false,
            }
          : false
      }
      columns={columns}
      dataSource={dataSource}
      rowClassName={(record: AnyObject) =>
        `${onRow ? "cursor-pointer" : ""} ${rowClassName ? rowClassName(record) : ""}`
      }
    />
  );
};

export default BasicTable;
