import * as t from "io-ts";
import { UserProfileEnumV } from "@type/core/user/profile.types";
import { UserStatusEnumV } from "@type/core/user/status.types";

export const userItemResponseDtoDecoderContent = {
  id: t.string,
  name: t.string,
  email: t.string,
  profiles: t.array(UserProfileEnumV),
  status: UserStatusEnumV,
};

export const userItemResponseDtoDecoder = t.type(
  userItemResponseDtoDecoderContent,
);

export type UserItemResponseDto = t.TypeOf<typeof userItemResponseDtoDecoder>;
