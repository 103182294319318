import i18n from "i18next";

const MILLISECONDS_IN_A_DAY = 1000 * 3600 * 24;

export function differenceInYearsMonthsDays(date1: Date, date2: Date): string {
  if (date1 > date2) {
    return `0 ${i18n.t("common.DAY")}`;
  }
  let years = date2.getFullYear() - date1.getFullYear();
  let months = date2.getMonth() - date1.getMonth();
  let days = date2.getDate() - date1.getDate();

  if (months < 0) {
    years--;
    months += 12;
  }

  if (days < 0) {
    months--;
    const previousMonth = new Date(date2.getFullYear(), date2.getMonth(), 0);
    days += previousMonth.getDate();
  }

  if (months < 0) {
    years--;
    months += 12;
  }
  return `${years > 0 ? `${i18n.t("common.YEAR", { count: years })} ` : ""}${months > 0 ? `${i18n.t("common.MONTH", { count: months })} ` : ""} ${days > 0 ? i18n.t("common.DAY", { count: days }) : ""}`;
}

export function differenceInDaysInPercent(
  activationDate: Date,
  expirationDate: Date,
): number {
  if (
    activationDate.getTime() > Date.now() ||
    Date.now() > expirationDate.getTime()
  ) {
    return 0;
  }
  const totalTimeInDay: number = Math.round(
    (activationDate.getTime() - expirationDate.getTime()) /
      MILLISECONDS_IN_A_DAY,
  );

  const elapsedTimeInDay: number = Math.round(
    (activationDate.getTime() - Date.now()) / MILLISECONDS_IN_A_DAY,
  );

  return (elapsedTimeInDay / totalTimeInDay) * 100;
}
