import i18n from "i18next";

export enum TransactionStatusEnum {
  CREATED = "CREATED",
  AWAITING_CONTRACT = "AWAITING_CONTRACT",
  SENT = "SENT",
  COMPLETED = "COMPLETED",
  REFUSED = "REFUSED",
  EXPIRED = "EXPIRED",
}

export interface TransactionStatusProperties {
  id: string;
  label: string;
  variant: string;
}

export const transactionStatusProperties: Record<
  string,
  TransactionStatusProperties
> = {
  [TransactionStatusEnum.CREATED]: {
    id: TransactionStatusEnum.CREATED,
    label: i18n.t("transactions.status.CREATED.label"),
    variant: "warning",
  },
  [TransactionStatusEnum.AWAITING_CONTRACT]: {
    id: TransactionStatusEnum.AWAITING_CONTRACT,
    label: i18n.t("transactions.status.AWAITING_CONTRACT.label"),
    variant: "warning",
  },
  [TransactionStatusEnum.SENT]: {
    id: TransactionStatusEnum.SENT,
    label: i18n.t("transactions.status.SENT.label"),
    variant: "warning",
  },
  [TransactionStatusEnum.COMPLETED]: {
    id: TransactionStatusEnum.COMPLETED,
    label: i18n.t("transactions.status.COMPLETED.label"),
    variant: "success",
  },
  [TransactionStatusEnum.EXPIRED]: {
    id: TransactionStatusEnum.EXPIRED,
    label: i18n.t("transactions.status.EXPIRED.label"),
    variant: "warning",
  },
  [TransactionStatusEnum.REFUSED]: {
    id: TransactionStatusEnum.REFUSED,
    label: i18n.t("transactions.status.REFUSED.label"),
    variant: "danger",
  },
} as const;

export const TRANSACTION_STATUS_OPTIONS = [
  {
    label: i18n.t("transactions.status.toSign.label"),
    value: "SENT,AWAITING_CONTRACT",
  },
  {
    label: i18n.t("transactions.status.signed.label"),
    value: "COMPLETED",
  },
  {
    label: i18n.t("transactions.status.refused.label"),
    value: "REFUSED",
  },
];
