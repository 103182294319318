import { createStore } from "effector";
import { PreferenceContext } from "@/type/core/preferences/preference.types";
import { clearPreference, putPreference } from "./PreferenceEvents";

interface PreferenceContextWrapper {
  isLoading: boolean;
  preferences: PreferenceContext;
}

export const preferenceStore = createStore<PreferenceContextWrapper>({
  isLoading: true,
  preferences: {},
})
  .on(putPreference, (state, payload) => ({
    isLoading: false,
    preferences: {
      ...state.preferences,
      ...payload,
    },
  }))
  .on(clearPreference, () => ({ isLoading: true, preferences: {} }));
