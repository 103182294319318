import * as t from "io-ts";
import { UserProfileEnumV } from "@type/core/user/profile.types";
import { FeatureRightEnumV } from "@type/core/endpoint/endpoint.types";

export const accreditationResponseDtoDecoder = t.type({
  feature: t.string,
  right: FeatureRightEnumV,
});

export const profileDetailsResponseDtoDecoderContent = {
  name: UserProfileEnumV,
  accreditations: t.array(accreditationResponseDtoDecoder),
};

export const profileDetailsResponseDtoDecoder = t.type(
  profileDetailsResponseDtoDecoderContent,
);

export type ProfileDetailsResponseDto = t.TypeOf<
  typeof profileDetailsResponseDtoDecoder
>;
