import { isLeft } from "fp-ts/lib/Either";
import { PathReporter } from "io-ts/PathReporter";
import * as t from "io-ts";
import logger from "@utils/logger";

export type DecoderTypeComputer<PrimaryType extends t.Props | t.Mixed> =
  PrimaryType extends t.Props
    ? t.TypeC<PrimaryType>
    : PrimaryType extends t.Mixed
      ? t.ArrayC<PrimaryType>
      : never;

export const validateServerResponse = <
  PrimaryType extends t.Props | t.Mixed,
  DecoderType extends DecoderTypeComputer<PrimaryType>,
  Type extends t.TypeOf<DecoderType> = t.TypeOf<DecoderType>,
>(
  data: unknown,
  decoder?: DecoderType,
): t.TypeOf<DecoderType> => {
  if (decoder == undefined) {
    return data as Type;
  }
  const decodedData = decoder.decode(data);

  if (isLeft(decodedData)) {
    logger.log(
      `Could not validate data: ${PathReporter.report(decodedData).join("\n")}`,
    );
    throw Error(
      `Could not validate data: ${PathReporter.report(decodedData).join("\n")}`,
    );
  }

  return decodedData.right;
};
