import { FunctionComponent, useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ROUTES } from "@routes/Routes";
import BasicButton from "@components/core/buttons/BasicButton";
import { setUser } from "@state/auth/AuthEvents";
import { requestGetUserInfo, requestULink } from "@state/auth/AuthEffects";
import { toastError } from "@utils/helpers/toast-helper";
import { ParsedResponse } from "@utils/rest/ServerResponseParse";
import { computeRedirectUrlFrom } from "@utils/helpers/query-helper";
import { AuthResultResponseDto } from "@state/auth/dto/response/auth.result.response.dto";
import LoginLayout from "@components/core/layouts/LoginLayout";
import { Spin } from "antd";

const ULinkScreen: FunctionComponent = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorCode, setErrorCode] = useState<string>();

  const token = new URLSearchParams(location.search).get("token");
  const userId = new URLSearchParams(location.search).get("userId");

  const handleAuthentication = useCallback(() => {
    void requestGetUserInfo({}).then((ar) => {
      if (ar.ok && ar.data) {
        setUser(ar.data);
        const redirectUrl = computeRedirectUrlFrom(location.search);
        navigate(redirectUrl);
      }
    });
  }, [navigate, location.search]);

  useEffect(() => {
    if (userId && token) {
      requestULink({
        dto: {
          userId,
          token,
        },
      })
        .then((authResult: ParsedResponse<AuthResultResponseDto>) => {
          if (authResult.responseCode === 200 && authResult.data) {
            handleAuthentication();
          } else {
            if (authResult.errorCode) {
              toastError(t(`login.messages.errors.${authResult.errorCode}`));
              setErrorCode(authResult.errorCode);
            } else {
              toastError(t("login.messages.error"));
            }
          }
        })
        .catch(() => {
          toastError(t("login.messages.error"));
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [userId, token, t, handleAuthentication]);

  return (
    <LoginLayout title={errorCode ? t("ulink.title") : t("login.title")}>
      <>
        {isLoading && (
          <div className="h-100 flex-grow-1 d-flex align-items-center justify-content-center">
            <Spin />
          </div>
        )}
        {errorCode && (
          <>
            <div className="d-flex flex-column align-items-stretch gap-24">
              <p className="m-0">{t(`ulink.messages.errors.1006`)}</p>
              <BasicButton
                text={t("ulink.buttons.login")}
                onClick={() => {
                  navigate(ROUTES.auth.login.generate());
                }}
              />
            </div>
          </>
        )}
      </>
    </LoginLayout>
  );
};

export default ULinkScreen;
