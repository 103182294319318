import { JSX } from "react";
import { OptionType } from "@type/core/form/field.types";
import { UserOutlined, LinuxOutlined } from "@ant-design/icons";

export enum UserProfile {
  VETERINARY = "VETERINARY",
  OTHER = "OTHER",
}

export const findUserProfile = (profile: string): UserProfile | undefined => {
  const userProfiles = Object.keys(UserProfile);

  return userProfiles.includes(profile) ? (profile as UserProfile) : undefined;
};

export interface UserProfileProperties {
  id: string;
  label: string;
  icon: JSX.Element;
  description: string;
  level: number;
}

export const userProfilesProperties: Record<
  UserProfile,
  UserProfileProperties
> = {
  [UserProfile.VETERINARY]: {
    id: UserProfile.VETERINARY,
    label: "users.labels.profiles.VETERINARY.label",
    icon: <LinuxOutlined />,
    description: "users.labels.profiles.VETERINARY.description",
    level: 1,
  },
  [UserProfile.OTHER]: {
    id: UserProfile.OTHER,
    label: "users.labels.profiles.OTHER.label",
    icon: <UserOutlined />,
    description: "users.labels.profiles.OTHER.description",
    level: 2,
  },
} as const;

export const PROFILES_OPTIONS = Object.keys(UserProfile)
  .map((profile) => profile as UserProfile)
  .map((profileId) => ({
    value: profileId,
    label: userProfilesProperties[profileId].label,
  })) as OptionType[];
