import { createEffect } from "effector";
import {
  makeRestCall,
  restGetHandler,
  restGetUniqueHandler,
  restListHandler,
} from "@utils/rest/RestClient";
import configuration from "@utils/Config";
import {
  appInfoResponseDtoDecoder,
  appInfoResponseDtoDecoderContent,
  AppInfosResponseDto,
} from "@state/app/response/app.infos.response.dto";
import {
  ProfileDetailsResponseDto,
  profileDetailsResponseDtoDecoder,
} from "@state/app/response/profile.details.response.dto";
import {
  EndpointGroupDetailsResponseDto,
  endpointGroupDetailsResponseDtoDecoder,
} from "@state/app/response/endpoint.group.details.response.dto";
import {
  FeatureDetailsResponseDto,
  featureDetailsResponseDtoDecoder,
} from "@state/app/response/feature.details.response.dto";
import { LoggersTreeResponseDto } from "@state/app/response/loggers.tree.response.dto";
import { ParsedResponse } from "@utils/rest/ServerResponseParse";
import {
  ModuleErrorCodeItemResponseDto,
  moduleErrorCodeItemResponseDtoDecoder,
} from "@state/app/response/module.error.code.item.response.dto";
import * as t from "io-ts";

export const requestAppInfos = createEffect({
  handler: restGetUniqueHandler<
    AppInfosResponseDto,
    Record<string, unknown>,
    typeof appInfoResponseDtoDecoderContent,
    typeof appInfoResponseDtoDecoder
  >(
    configuration.backendBaseUrl + "/app/infos",
    undefined,
    appInfoResponseDtoDecoder,
  ),
});

const endpointGroupDetailsResponseDtoArrayDecoder = t.array(
  endpointGroupDetailsResponseDtoDecoder,
);

export const requestGetEndpoints = createEffect({
  handler: restListHandler<
    EndpointGroupDetailsResponseDto,
    Record<string, unknown>,
    typeof endpointGroupDetailsResponseDtoDecoder,
    typeof endpointGroupDetailsResponseDtoArrayDecoder
  >(
    configuration.backendBaseUrl + "/app/endpoints",
    undefined,
    endpointGroupDetailsResponseDtoArrayDecoder,
  ),
});

const profileDetailsResponseDtoArrayDecoder = t.array(
  profileDetailsResponseDtoDecoder,
);

export const requestGetProfiles = createEffect({
  handler: restListHandler<
    ProfileDetailsResponseDto,
    Record<string, unknown>,
    typeof profileDetailsResponseDtoDecoder,
    typeof profileDetailsResponseDtoArrayDecoder
  >(
    configuration.backendBaseUrl + "/app/profiles",
    undefined,
    profileDetailsResponseDtoArrayDecoder,
  ),
});

const featureDetailsResponseDtoArrayDecoder = t.array(
  featureDetailsResponseDtoDecoder,
);

export const requestGetFeatures = createEffect({
  handler: restListHandler<
    FeatureDetailsResponseDto,
    Record<string, unknown>,
    typeof featureDetailsResponseDtoDecoder,
    typeof featureDetailsResponseDtoArrayDecoder
  >(
    configuration.backendBaseUrl + "/app/features",
    undefined,
    featureDetailsResponseDtoArrayDecoder,
  ),
});

export const requestGetLoggers = createEffect({
  handler: restGetHandler<LoggersTreeResponseDto>(
    configuration.backendBaseUrl + "/app/logs",
  ),
});

export const restUpdateLoggerLevelHandler = (
  endpointUrl: string,
): ((data: {
  logger: string;
  level: string;
}) => Promise<ParsedResponse<void>>) => {
  return (data: { logger: string; level: string }) =>
    makeRestCall<{ configuredLevel: string }>(
      endpointUrl + "/" + data.logger,
      "POST",
      {
        configuredLevel: data.level,
      },
    );
};

export const requestUpdateLoggerLevel = createEffect({
  handler: restUpdateLoggerLevelHandler(
    configuration.adminBaseUrl + "/actuator/loggers",
  ),
});

const moduleErrorCodeItemResponseDtoArrayDecoder = t.array(
  moduleErrorCodeItemResponseDtoDecoder,
);

export const requestGetApiErrors = createEffect({
  handler: restListHandler<
    ModuleErrorCodeItemResponseDto,
    Record<string, unknown>,
    typeof moduleErrorCodeItemResponseDtoDecoder,
    typeof moduleErrorCodeItemResponseDtoArrayDecoder
  >(
    configuration.backendBaseUrl + "/app/api/errors",
    undefined,
    moduleErrorCodeItemResponseDtoArrayDecoder,
  ),
});
