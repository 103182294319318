const PROD_MODE = process.env.NODE_ENV === "production";
const NOOP = () => {
  /* Disable log */
}; // eslint-disable-line
const PREFIX = "INIT";

const DATE_FORMAT = ((formatter: Intl.DateTimeFormat) => {
  return (date: Date) => {
    return formatter.format(date);
  };
})(
  new Intl.DateTimeFormat("fr-FR", {
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
  }),
);

const color = (level: string) => {
  let levelColor = "black";
  switch (level) {
    case "debug":
      levelColor = "DarkMagenta";
      break;
    case "info":
      levelColor = "DarkGreen";
      break;
    case "log":
      levelColor = "DarkBlue";
      break;
    case "warn":
      levelColor = "DarkOrange";
      break;
    case "error":
      levelColor = "DarkRed";
      break;
    case "exception":
      levelColor = "Crimson";
      break;
    case "trace":
      levelColor = "DarkOrchid";
      break;
  }
  return levelColor;
};

const logMessage = (level: string, message?: unknown) => {
  return [
    `%c[${PREFIX}][${DATE_FORMAT(new Date())}][${level
      .toUpperCase()
      .padStart(5, " ")}]%c - ${String(message)}`,
    `color: ${color(level)}; background-color: #D0D0D0`,
    "color: auto; background-color: auto",
  ];
};

/* eslint-disable @typescript-eslint/no-unsafe-argument */
export default {
  assert: PROD_MODE
    ? NOOP
    : (condition?: boolean, message?: string, ...data: unknown[]): void => {
        console.assert(condition, message, data);
      },
  clear: PROD_MODE
    ? NOOP
    : (): void => {
        console.clear();
      },
  count: PROD_MODE
    ? NOOP
    : (label?: string): void => {
        console.count(label);
      },
  debug: PROD_MODE
    ? NOOP
    : (message?: unknown, ...optionalParams: unknown[]) => {
        console.debug(...logMessage("debug", message), ...optionalParams);
      },
  dir: PROD_MODE
    ? NOOP
    : (value?: unknown, ...optionalParams: unknown[]): void => {
        console.dir(value, ...optionalParams);
      },
  dirxml: PROD_MODE
    ? NOOP
    : (value: unknown): void => {
        console.dirxml(value);
      },
  error: PROD_MODE
    ? NOOP
    : (message?: unknown, ...optionalParams: unknown[]): void => {
        console.error(...logMessage("error", message), ...optionalParams);
      },
  exception: PROD_MODE
    ? NOOP
    : (message?: string, ...optionalParams: unknown[]): void => {
        console.error(...logMessage("exception", message), ...optionalParams);
      },
  group: PROD_MODE
    ? NOOP
    : (groupTitle?: string, ...optionalParams: unknown[]): void => {
        console.group(groupTitle, ...optionalParams);
      },
  groupCollapsed: PROD_MODE
    ? NOOP
    : (groupTitle?: string, ...optionalParams: unknown[]): void => {
        console.groupCollapsed(groupTitle, ...optionalParams);
      },
  groupEnd: PROD_MODE
    ? NOOP
    : (): void => {
        console.groupEnd();
      },
  info: PROD_MODE
    ? NOOP
    : (message?: unknown, ...optionalParams: unknown[]): void => {
        console.info(...logMessage("info", message), ...optionalParams);
      },
  log: PROD_MODE
    ? NOOP
    : (message?: unknown, ...optionalParams: unknown[]): void => {
        console.log(...logMessage("log", message), ...optionalParams);
      },
  profile: PROD_MODE
    ? NOOP
    : (reportName?: string): void => {
        console.profile(reportName);
      },
  profileEnd: PROD_MODE
    ? NOOP
    : (reportName?: string): void => {
        console.profileEnd(reportName);
      },
  table: PROD_MODE
    ? NOOP
    : (...tabularData: unknown[]): void => {
        console.table(tabularData);
      },
  time: PROD_MODE
    ? NOOP
    : (label?: string): void => {
        console.time(label);
      },
  timeEnd: PROD_MODE
    ? NOOP
    : (label?: string): void => {
        console.timeEnd(label);
      },
  timeStamp: PROD_MODE
    ? NOOP
    : (label?: string): void => {
        console.timeStamp(label);
      },
  trace: PROD_MODE
    ? NOOP
    : (message?: unknown, ...optionalParams: unknown[]): void => {
        console.trace(...logMessage("trace", message), ...optionalParams);
      },
  warn: PROD_MODE
    ? NOOP
    : (message?: unknown, ...optionalParams: unknown[]): void => {
        console.warn(...logMessage("warn", message), ...optionalParams);
      },
};
/* eslint-enable @typescript-eslint/no-unsafe-argument */
