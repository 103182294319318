import { FunctionComponent, JSX } from "react";
import { LINK_DEFAULT_TITLE } from "@utils/Constant";
import BasicLink from "@components/core/links/BasicLink";

interface LinkButtonProps {
  to: string;
  className?: string;
  target?: string;
  title?: string;
  onClick?: () => void;
  size?: "sm" | "md";
  icon?: JSX.Element;
  iconRight?: boolean;
  variant?: string;
  text?: string | JSX.Element;
  disabled?: boolean;
}

const LinkButton: FunctionComponent<LinkButtonProps> = ({
  to,
  className = "",
  target,
  title = LINK_DEFAULT_TITLE,
  onClick,
  size = "md",
  icon,
  iconRight = false,
  variant = "primary",
  text = "",
  disabled = false,
}) => {
  return (
    <>
      <BasicLink
        to={to}
        target={target}
        onClick={onClick}
        title={title}
        className={`app-link-btn text-decoration-none btn-${variant} ${
          disabled ? "btn-disabled" : ""
        } btn-${size} ${text === "" ? "btn-icon" : ""} ${iconRight ? "btn-icon-right" : ""} ${className}`}
      >
        <>
          {icon && <div className="icon">{icon}</div>}
          {text !== "" && <div className="text text-ellipsis">{text}</div>}
        </>
      </BasicLink>
    </>
  );
};

export default LinkButton;
