import * as t from "io-ts";

export const appInfoResponseDtoDecoderContent = {
  environment: t.string,
  branch: t.string,
  commitId: t.string,
  commitDate: t.string,
  version: t.string,
};

export const appInfoResponseDtoDecoder = t.type(
  appInfoResponseDtoDecoderContent,
);

export type AppInfosResponseDto = t.TypeOf<typeof appInfoResponseDtoDecoder>;
