import * as t from "io-ts";

export interface Pageable<D> {
  content: D[];
  numberOfElements: number;
  totalElements: number;
  size: number;
  totalPages: number;
  number: number;
}

export function createPageableDecoderContent<C extends t.Mixed>(codec: C) {
  return {
    content: t.array(codec),
    numberOfElements: t.number,
    totalElements: t.number,
    size: t.number,
    totalPages: t.number,
    number: t.number,
  };
}

export function createPageableDecoder<C extends t.Mixed>(codec: C) {
  return t.type(createPageableDecoderContent(codec));
}
