import { FunctionComponent, JSX } from "react";
import { Button, Tooltip } from "antd";

export interface BasicButtonProps {
  type?: "button" | "submit" | "reset";
  size?: "sm" | "md";
  icon?: JSX.Element;
  iconRight?: boolean;
  variant?: string;
  text?: string | JSX.Element;
  disabled?: boolean;
  onClick?: (e: unknown) => void;
  className?: string;
  isLoading?: boolean;
  dataTest?: string;
  tooltip?: string;
}

const BasicButton: FunctionComponent<BasicButtonProps> = ({
  type = "button",
  size = "md",
  icon,
  iconRight = false,
  variant = "primary",
  text = "",
  disabled = false,
  onClick = undefined,
  isLoading = false,
  className = "",
  dataTest = "",
  tooltip,
}) => {
  const button = (
    <Button
      htmlType={type}
      onClick={onClick}
      disabled={disabled}
      loading={isLoading}
      className={`btn-${variant} btn-${size} ${text === "" ? "btn-icon" : ""} ${iconRight ? "btn-icon-right" : ""} ${className}`}
      data-testid={"basic-button"}
      data-test={dataTest || "button"}
      data-alt={text !== "" ? text : "button"}
    >
      {icon && (
        <div data-testid="basic-button-icon" className="icon">
          {icon}
        </div>
      )}
      {text !== "" && <div className="text text-ellipsis">{text}</div>}
    </Button>
  );

  return tooltip ? (
    <Tooltip title={tooltip} mouseEnterDelay={0.2} placement={"top"}>
      <div data-testid={"basic-button-tooltip"}>{button}</div>
    </Tooltip>
  ) : (
    button
  );
};

export default BasicButton;
