import { FunctionComponent, JSX, ReactElement } from "react";
import { useTranslation } from "react-i18next";
import BasicButton from "@components/core/buttons/BasicButton";

interface SubmitButtonProps {
  module: string;
  isSubmitting?: boolean;
  variant?: string;
  size?: "sm" | "md";
  label?: string;
  icon?: JSX.Element;
  iconRight?: boolean;
  className?: string;
  disabled?: boolean;
}

const SubmitButton: FunctionComponent<SubmitButtonProps> = ({
  module,
  variant = "primary",
  size = "md",
  label,
  icon,
  iconRight,
  isSubmitting = false,
  className = "",
  disabled = false,
}: SubmitButtonProps): ReactElement => {
  const { t } = useTranslation();
  const submitButtonLabel = label
    ? `${module}.actions.${label}`
    : "forms.buttons.submit.defaultLabel";

  return (
    <BasicButton
      type="submit"
      isLoading={isSubmitting}
      variant={variant}
      size={size}
      text={t(submitButtonLabel)}
      icon={icon}
      iconRight={iconRight}
      className={className}
      disabled={disabled}
    />
  );
};

export default SubmitButton;
