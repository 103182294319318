import * as t from "io-ts";
import {
  restCreationHandler,
  restDetailsHandler,
  restListHandler,
  restListHandlerPagination,
  restPostHandler,
  restUpdateHandler,
} from "@utils/rest/RestClient";
import {
  UserItemResponseDto,
  userItemResponseDtoDecoder,
} from "@state/users/dto/response/user.item.response.dto";
import { createEffect } from "effector";
import configuration from "@utils/Config";
import { UserWorkflowRequestDto } from "@state/users/dto/request/user.workflow.request.dto";
import {
  UserDetailsResponseDto,
  userDetailsResponseDtoDecoder,
  userDetailsResponseDtoDecoderContent,
} from "@state/users/dto/response/user.details.response.dto";
import { UserSaveRequestDto } from "@state/users/dto/request/user.save.request.dto";
import { UsersImportRequestDto } from "@state/users/dto/request/users.import.request.dto";
import {
  UsersImportSynthesisDto,
  usersImportSynthesisDtoDecoder,
  usersImportSynthesisDtoDecoderContent,
} from "@state/users/dto/response/users.import.synthesis.dto";
import { UserSelfUpdateRequestDto } from "@state/users/dto/request/user.self.update.request.dto";
import { UserSearchCriteria } from "@state/users/dto/request/user.search.criteria";
import { UserTransitionEnum } from "@utils/enums/user.status.enum";
import {
  createPageableDecoder,
  createPageableDecoderContent,
} from "@type/core/list/pagination.types";
import { UserTransitionsEnumV } from "@type/core/user/user.transitions.types";
import {
  userUpdateResponseDtoDecoder,
  userUpdateResponseDtoDecoderContent,
} from "@state/users/dto/response/user.update.response.dto";
import { PasswordUpdateRequestDto } from "@state/users/dto/request/password.update.request.dto";

const baseUrl = configuration.backendBaseUrl;
const usersUrl = baseUrl + "/users/";

const userItemPageableDecoderContent = createPageableDecoderContent(
  userItemResponseDtoDecoder,
);

const userItemPageableDecoder = createPageableDecoder(
  userItemResponseDtoDecoder,
);

export const requestSearchUsers = createEffect({
  handler: restListHandlerPagination<
    UserItemResponseDto,
    UserSearchCriteria,
    typeof userItemPageableDecoderContent,
    typeof userItemPageableDecoder
  >(usersUrl, userItemPageableDecoder),
});

export const requestGetUser = createEffect({
  handler: restDetailsHandler<
    UserDetailsResponseDto,
    typeof userDetailsResponseDtoDecoderContent,
    typeof userDetailsResponseDtoDecoder
  >(usersUrl, undefined, userDetailsResponseDtoDecoder),
});

export const requestCreateUser = createEffect({
  handler: restCreationHandler<
    UserSaveRequestDto,
    UserDetailsResponseDto,
    typeof userDetailsResponseDtoDecoderContent,
    typeof userDetailsResponseDtoDecoder
  >(usersUrl, undefined, undefined, userDetailsResponseDtoDecoder),
});

export const requestUpdateUser = createEffect({
  handler: restUpdateHandler<
    UserSaveRequestDto,
    UserDetailsResponseDto,
    typeof userDetailsResponseDtoDecoderContent,
    typeof userDetailsResponseDtoDecoder
  >(usersUrl, undefined, userDetailsResponseDtoDecoder),
});

export const requestUpdateUserPassword = createEffect({
  handler: restPostHandler<PasswordUpdateRequestDto>(
    usersUrl + "update-password",
  ),
});

export const requestSelfUpdateUser = createEffect({
  handler: restPostHandler<
    UserSelfUpdateRequestDto,
    UserDetailsResponseDto,
    typeof userUpdateResponseDtoDecoderContent,
    typeof userUpdateResponseDtoDecoder
  >(usersUrl + "me", undefined, userUpdateResponseDtoDecoder),
});

export const requestUpdateUserStatus = createEffect({
  handler: restUpdateHandler<UserWorkflowRequestDto, string>(
    usersUrl,
    "/transitions",
  ),
});

export const requestUsersImport = createEffect({
  handler: restPostHandler<
    UsersImportRequestDto,
    UsersImportSynthesisDto,
    typeof usersImportSynthesisDtoDecoderContent,
    typeof usersImportSynthesisDtoDecoder
  >(usersUrl + "import"),
});

export const requestReinitUserPassword = createEffect({
  handler: restDetailsHandler(usersUrl, "/reinit-password"),
});

const userTransitionsArrayDecoder = t.array(UserTransitionsEnumV);

export const requestGetUserTransitions = createEffect({
  handler: restListHandler<
    UserTransitionEnum,
    Record<string, unknown>,
    typeof UserTransitionsEnumV,
    typeof userTransitionsArrayDecoder
  >(usersUrl, "/transitions", userTransitionsArrayDecoder),
});
