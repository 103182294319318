import i18n from "i18next";
import { ColumnsType } from "antd/lib/table/interface";
import DateFormat from "@utils/DateFormat";
import { AnyObject } from "antd/es/_util/type";
import IconMore from "@static/core/img/icon-more.svg?react";
import { FormInstance, Popover } from "antd";
import {
  differenceInDaysInPercent,
  differenceInYearsMonthsDays,
} from "@utils/helpers/date-helper";
import BasicProgress from "@components/core/progress/BasicProgress";
import BasicLink from "@components/core/links/BasicLink";
import InputFormField from "@components/core/inputs/InputFormField";
import BasicButton from "@components/core/buttons/BasicButton";
import { CheckOutlined } from "@ant-design/icons";
import { showConfirm } from "@components/core/modals/ConfirmModal";

export const computeOrdinalNumberField = (ordinalNumber: string): string => {
  return `ordinalNumber ${ordinalNumber}`;
};

export const columns = (
  form: FormInstance,
  handleAttributeLicense: (licenseId: string) => void,
  handleRemoveLicense: (licenseId: string, ordinalNumber: string) => void,
): ColumnsType<AnyObject> => [
  {
    title: i18n.t("licenses.licensesManagement.table.licenseNumber"),
    dataIndex: "key",
    sorter: true,
    showSorterTooltip: false,
  },
  {
    title: i18n.t("licenses.licensesManagement.table.duration"),
    dataIndex: "durationValue",
    render: (durationValue: number, record) => {
      return durationValue > 1
        ? i18n.t(`common.${record.durationUnit}_other`, {
            count: durationValue,
          })
        : i18n.t(`common.${record.durationUnit}_one`, { count: durationValue });
    },
  },
  {
    title: i18n.t("licenses.licensesManagement.table.period"),
    dataIndex: "activationDate",
    className: "change-color-with-status",
    render: (startPeriod: string, record) => {
      return startPeriod && record.expirationDate
        ? `${DateFormat.dashboardDate(new Date(startPeriod))}${record.expirationDate ? ` ${i18n.t("licenses.myLicense.to")} ${DateFormat.dashboardDate(new Date(record.expirationDate as string))}` : ""}`
        : i18n.t("licenses.waitingAssignment");
    },
  },
  {
    title: i18n.t("licenses.licensesManagement.table.remainingDuration"),
    dataIndex: "expirationDate",
    className: "change-color-with-status",
    width: 202,
    sorter: true,
    showSorterTooltip: false,
    render: (expirationDate: string, record) => {
      return record.activationDate && expirationDate ? (
        <div className="d-flex flex-column gap-10">
          <p className="m-0 d-flex align-items-center justify-content-between gap-8">
            <span>
              {differenceInYearsMonthsDays(
                new Date(),
                new Date(expirationDate),
              )}
            </span>
            {new Date() > new Date(record.expirationDate as string) && (
              <BasicLink to="#">
                {i18n.t("licenses.licensesManagement.renew")}
              </BasicLink>
            )}
          </p>
          <BasicProgress
            percent={differenceInDaysInPercent(
              new Date(record.activationDate as string),
              new Date(expirationDate),
            )}
          />
        </div>
      ) : (
        <div className="d-flex flex-column gap-10">
          <span>
            {i18n.t(`common.${record.durationUnit}`, {
              count: record.durationValue as number,
            })}
          </span>
          <BasicProgress percent={100} disabled />
        </div>
      );
    },
  },
  {
    title: i18n.t("licenses.licensesManagement.table.assignBy"),
    sorter: true,
    showSorterTooltip: false,
    render: (record: AnyObject) => {
      return record.holderName ? (
        `${record.holderName} (#${record.ordinalNumber})`
      ) : (
        <InputFormField
          form={form}
          module="licenses.form"
          field={computeOrdinalNumberField(record.id as string)}
          placeholder={i18n.t("licenses.form.fields.ordinalNumber.placeholder")}
          className="mb-0"
          maxLength={20}
          rules={[
            {
              pattern: /^[a-zA-Z0-9]{1,20}$/,
              message: (
                <span>{i18n.t("forms.errors.ordinal-number-invalid")}</span>
              ),
            },
          ]}
        />
      );
    },
  },
  {
    title: "",
    dataIndex: "holderName",
    width: 80,
    render: (holderName: string, record: AnyObject) => {
      return holderName ? (
        <div className="d-flex align-items-center justify-content-end">
          <Popover
            content={
              <>
                <div
                  className={"mb-16 cursor-pointer"}
                  onClick={() => {
                    const name = record.holderName as string;
                    const ordinalNumber = record.ordinalNumber as string;
                    void showConfirm({
                      title: i18n.t("licenses.actions.remove.title"),
                      content:
                        i18n.t("licenses.actions.remove.content") +
                        ` ${name} (${ordinalNumber})`,
                    }).then((confirmed) => {
                      if (confirmed) {
                        handleRemoveLicense(record.id as string, ordinalNumber);
                      }
                    });
                  }}
                >
                  {i18n.t("licenses.actions.unassign")}
                </div>
                <div className={"mt-16 cursor-pointer"}>
                  {i18n.t("licenses.actions.renew")}
                </div>
              </>
            }
            placement="bottomRight"
            trigger="hover"
            arrow={false}
          >
            <IconMore className="cursor-pointer" />
          </Popover>
        </div>
      ) : (
        <div className="d-flex align-items-center justify-content-end">
          <BasicButton
            size="sm"
            variant="primary-outlined"
            icon={<CheckOutlined />}
            onClick={() => {
              void showConfirm({
                title: i18n.t("licenses.actions.attribute.title"),
                content: i18n.t("licenses.actions.attribute.content"),
              }).then((confirmed) => {
                if (confirmed) {
                  handleAttributeLicense(record.id as string);
                }
              });
            }}
          />
        </div>
      );
    },
  },
];
