import { createStore } from "effector";
import {
  authenticationContextEvent,
  logout,
  setUser,
  clearUser,
} from "@state/auth/AuthEvents";
import { AuthenticationContext } from "@type/core/auth/auth.types";
import logger from "@utils/logger";
import { requestLogout } from "@state/auth/AuthEffects";
import { clearPreference } from "../preferences/PreferenceEvents";
import { AuthenticatedUserResponseDto } from "@state/auth/dto/response/authenticated.user.response.dto";

export const authenticationStore = createStore<AuthenticationContext | null>(
  null,
);

authenticationStore
  .on(authenticationContextEvent, (_, payload) => {
    return payload === undefined
      ? null
      : {
          ...payload,
        };
  })
  .on<AuthenticatedUserResponseDto>(
    setUser,
    (state, payload: AuthenticatedUserResponseDto): AuthenticationContext => {
      return {
        ...state,
        user: { ...payload },
      };
    },
  )
  .on(clearUser, (state) => ({
    ...state,
    user: undefined,
  }));

logout.watch(() => {
  void requestLogout({})
    .then((response) => {
      if (response.ok) {
        localStorage.clear();
        sessionStorage.clear();
        clearUser();
        clearPreference();
      }
    })
    .catch((err: unknown): void => {
      logger.error(err);
    });
});
