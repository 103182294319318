import { FunctionComponent } from "react";
import { passwordHelper } from "@utils/helpers/password-helper";
import { useTranslation } from "react-i18next";
import { PasswordStrengthConstraintsDetailsResponseDto } from "@state/auth/dto/response/password.strength.constraints.details.response.dto";

interface PasswordValidationTooltipProps {
  password: string;
  strengthConstraints: PasswordStrengthConstraintsDetailsResponseDto;
}

const prefix = "account.set-password";

const getSuffix = (value: number) => {
  return value > 1 ? "_plural" : "";
};

const PasswordValidationTooltip: FunctionComponent<
  PasswordValidationTooltipProps
> = ({ password, strengthConstraints }: PasswordValidationTooltipProps) => {
  const { t } = useTranslation();

  return (
    <div className="custom-error constant">
      {t(`${prefix}.fields.credential.errors.title`)}
      <ul>
        <li
          className={
            passwordHelper.validateCharacter(password, strengthConstraints)
              ? "custom-valid"
              : ""
          }
        >
          {t(
            `${prefix}.fields.credential.errors.character${getSuffix(
              strengthConstraints.minCharacters,
            )}`,
            {
              count: strengthConstraints.minCharacters,
            },
          )}
        </li>
        <li
          className={
            passwordHelper.validateUpperCase(password, strengthConstraints)
              ? "custom-valid"
              : ""
          }
        >
          {t(
            `${prefix}.fields.credential.errors.upperCase${getSuffix(
              strengthConstraints.minUpperCaseLetters,
            )}`,
            {
              count: strengthConstraints.minUpperCaseLetters,
            },
          )}
        </li>
        <li
          className={
            passwordHelper.validateLowerCase(password, strengthConstraints)
              ? "custom-valid"
              : ""
          }
        >
          {t(
            `${prefix}.fields.credential.errors.lowerCase${getSuffix(
              strengthConstraints.minLowerCaseLetters,
            )}`,
            {
              count: strengthConstraints.minLowerCaseLetters,
            },
          )}
        </li>
        <li
          className={
            passwordHelper.validateNumber(password, strengthConstraints)
              ? "custom-valid"
              : ""
          }
        >
          {t(
            `${prefix}.fields.credential.errors.number${getSuffix(
              strengthConstraints.minDigits,
            )}`,
            {
              count: strengthConstraints.minDigits,
            },
          )}
        </li>
        <li
          className={
            passwordHelper.validateSpecial(password, strengthConstraints)
              ? "custom-valid"
              : ""
          }
        >
          {t(
            `${prefix}.fields.credential.errors.special${getSuffix(
              strengthConstraints.minSpecialCharacters,
            )}`,
            {
              count: strengthConstraints.minSpecialCharacters,
            },
          )}
        </li>
      </ul>
    </div>
  );
};

export default PasswordValidationTooltip;
