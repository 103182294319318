import { FunctionComponent, ReactElement } from "react";
import { Link } from "react-router-dom";
import configuration from "@utils/Config";
import { LINK_DEFAULT_TITLE } from "@utils/Constant";

interface BasicLinkProps {
  to: string;
  children: string | ReactElement;
  className?: string;
  target?: string;
  title?: string;
  dataAlt?: string;
  onClick?: () => void;
}

const BasicLink: FunctionComponent<BasicLinkProps> = ({
  to,
  children,
  className,
  target,
  title = LINK_DEFAULT_TITLE,
  onClick,
  dataAlt,
}) => {
  const isExternalLink = (): boolean => {
    return (
      !to.startsWith(configuration.frontendBaseUrl) &&
      !to.startsWith("/") &&
      !to.startsWith("#")
    );
  };

  const shouldCompleteUrl = (): boolean => {
    if (!to) {
      return false;
    }

    return isExternalLink() && !to.startsWith("http");
  };

  const computeUrl = (): string => {
    if (!to) {
      return "#";
    }

    return `${shouldCompleteUrl() ? "http://" : ""}${to}`;
  };

  const shouldAddNoFollowAttribute = (): boolean => {
    return isExternalLink();
  };

  const shouldSecureTargetBlank = (): boolean => {
    return isExternalLink() || target === "_blank";
  };

  const computeRelAttribute = (): string | undefined => {
    if (!shouldAddNoFollowAttribute() && !shouldSecureTargetBlank()) {
      return undefined;
    }
    return `${shouldAddNoFollowAttribute() ? "nofollow" : ""} ${
      shouldSecureTargetBlank() ? "noreferrer noopener" : ""
    }`;
  };

  return (
    <>
      {isExternalLink() ? (
        <a
          href={computeUrl()}
          className={className}
          rel={computeRelAttribute()}
          onClick={onClick}
          target={target ?? (isExternalLink() ? "_blank" : "")}
          title={title}
          data-test="link"
          data-alt={dataAlt}
        >
          {children}
        </a>
      ) : (
        <Link
          to={to}
          className={className}
          rel={computeRelAttribute()}
          onClick={onClick}
          target={target}
          title={title}
          data-test="link"
          data-alt={dataAlt}
        >
          {children}
        </Link>
      )}
    </>
  );
};

export default BasicLink;
