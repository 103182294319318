import * as t from "io-ts";

export const authorizationDetailResponseDtoDecoderContent = {
  submittedBy: t.union([t.string, t.null]),
  status: t.string,
  statusDate: t.string,
  lastTransactionDate: t.string,
  authorizationId: t.string,
};

export const authorizationDetailResponseDtoDecoder = t.type(
  authorizationDetailResponseDtoDecoderContent,
);

export type AuthorizationDetailResponseDto = t.TypeOf<
  typeof authorizationDetailResponseDtoDecoder
>;
