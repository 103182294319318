import PageLayout from "@components/core/layouts/PageLayout";
import { FunctionComponent, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  requestAvailableUsers,
  requestSelectUser,
} from "@state/e2e/E2eEffects";
import BasicCard from "@components/core/cards/BasicCard";
import { ROUTES } from "@routes/Routes";
import { UserItemResponseDto } from "@state/users/dto/response/user.item.response.dto";
import { toastError } from "@utils/helpers/toast-helper";
import { getProfilePropertiesFrom } from "@utils/helpers/profile-helper";
import { UserStatus } from "@utils/enums/user.status.enum";

const SelectUserScreen: FunctionComponent = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [availableUsers, setAvailableUsers] = useState<UserItemResponseDto[]>(
    [],
  );

  useEffect(() => {
    void requestAvailableUsers({});
  }, []);

  useEffect(() => {
    return requestAvailableUsers.done.watch(({ result }) => {
      if (result.ok && result.data) {
        setAvailableUsers(result.data);
      } else {
        toastError(t("Erreur lors du chargement des utilisateurs"));
      }
    });
  });

  const onClick = (userEmail: string) => {
    void requestSelectUser({ id: userEmail }).then((result) => {
      if (result.ok) {
        navigate(ROUTES.connected.home.generate());
      }
    });
  };

  const statusMapping: Record<UserStatus, boolean> = {
    INIT: true,
    INIT_WITH_SSO: true,
    ACTIVE: true,
    DISABLED: false,
    INACTIVE: false,
    PENDING: false,
  };

  return (
    <PageLayout title="Utilisateurs" contentInCard>
      <>
        <div className="text-center">
          Sélectionner un utilisateur afin de vous connecter avec son profil
        </div>
        <div className="d-flex align-items-center justify-content-center flex-wrap mt-8">
          {availableUsers.map((user) => (
            <BasicCard
              className={`m-8 card-dashboard ${
                statusMapping[user.status] ? "" : "card-disabled"
              }`}
              key={user.email}
              onClick={() => {
                statusMapping[user.status]
                  ? onClick(user.email)
                  : toastError(
                      "L'utilisateur n'a pas accès à l'application (compte bloqué, inactif, etc.)",
                    );
              }}
              dataTestFor={`user-${user.email}`}
            >
              <div className="d-flex flex-column align-items-center justify-content-center">
                <div className="mt-24">
                  {user.profiles
                    .map((profile) => getProfilePropertiesFrom(profile))
                    .map((profile) => (
                      <span key={`icon_${profile.id}`}>{profile.icon}</span>
                    ))}
                </div>
                <div className="mt-24 mb-8 dashboard-card-text text-center">
                  {user.name}
                </div>
                <div className="mb-24">
                  {user.profiles
                    .map((profile) => getProfilePropertiesFrom(profile))
                    .map((profile) => (
                      <span key={`card_${profile.id}`}>
                        {t(profile.label)}
                        <br />
                      </span>
                    ))}
                </div>
              </div>
            </BasicCard>
          ))}
        </div>
      </>
    </PageLayout>
  );
};

export default SelectUserScreen;
