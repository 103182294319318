import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useUnit } from "effector-react";
import { authenticationStore } from "@state/auth/AuthStore";
import { computeRedirectUrlFrom } from "@/utils/helpers/query-helper";

export const useAuthentificationCheck = (): void => {
  const navigate = useNavigate();

  const authenticationContext = useUnit(authenticationStore);

  useEffect(() => {
    if (!!authenticationContext && !!authenticationContext.user) {
      const redirectUrl = computeRedirectUrlFrom(location.search);
      navigate(redirectUrl);
    }
  }, [navigate, authenticationContext]);
};
