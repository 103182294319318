import { LanguageType } from "@type/core/language/language.types";

export const LANGUAGES = [
  {
    type: "FR",
    code: "fr",
  },
  {
    type: "EN",
    code: "en",
  },
] as LanguageType[];
