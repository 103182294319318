import { FunctionComponent, ReactElement } from "react";
import { Modal } from "antd";
import { useTranslation } from "react-i18next";
import DisplayDocument from "@views/core/document/DisplayDocument";

interface Props {
  visible: boolean;
  onCancel: () => void;
  mimeType: string;
  rotate: number;
  data?: string;
}

const DisplayDocumentModal: FunctionComponent<Props> = ({
  visible,
  onCancel,
  mimeType,
  data,
  rotate,
}): ReactElement => {
  const { t } = useTranslation();

  return (
    <Modal
      className="DisplayDocumentModal"
      centered
      open={visible}
      onOk={onCancel}
      onCancel={onCancel}
      cancelText={t("buttons.cancel")}
      okText={t("buttons.ok")}
    >
      <DisplayDocument
        className="Modal"
        mimeType={mimeType}
        data={data}
        rotateProps={rotate}
        width={window.innerWidth * 0.8}
      />
    </Modal>
  );
};

export default DisplayDocumentModal;
