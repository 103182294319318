const thousandSeparator = (value: number | string, separator = " "): string => {
  if ((!value && value !== 0) || Number.isNaN(value)) {
    return "";
  }
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, separator);
};

const ceil = (value: number, digits = 0): number | null => {
  if ((!value && value !== 0) || Number.isNaN(value)) {
    return null;
  }
  const multiplier = Math.pow(10, digits);
  return Math.ceil(value * multiplier) / multiplier;
};

const round = (value: number, digits = 0): number | null => {
  if ((!value && value !== 0) || Number.isNaN(value)) {
    return null;
  }
  const multiplier = Math.pow(10, digits);
  return Math.round(value * multiplier) / multiplier;
};

const toPrice = (value: number | undefined | null, currency = "€"): string => {
  if (value === undefined || value === null) {
    return "";
  }
  return `${thousandSeparator(value.toFixed(2))
    .replace(".00", "")
    .replace(".", ",")} ${currency}`;
};

const priceFormatter = (value?: number): string => {
  if (!value && value !== 0) {
    return "";
  }
  const number = parseFloat(value.toString());
  return thousandSeparator(Math.round(number * 100) / 100)
    .replace(".00", "")
    .replace(".", ",");
};

const priceParser = (value?: string): number => {
  if (!value && value !== "0") {
    return 0;
  }
  return parseFloat(
    value.replace(/ €/, "").replace(/\s/g, "").replace(",", "."),
  );
};

const mod = (n: number, m: number): number => {
  return ((n % m) + m) % m;
};

export const NumberFormat = {
  ceil,
  round,
  thousandSeparator,
  toPrice,
  priceFormatter,
  priceParser,
  mod,
};
