import { FunctionComponent, JSX } from "react";
import { FormInstance } from "antd";
import { InternalNamePath } from "rc-field-form/lib/interface";

interface FloatingLabelProps {
  label: string | JSX.Element;
  field: string | InternalNamePath;
  form: FormInstance;
  children: string | JSX.Element;
  forceLabelRerender?: boolean;
  className?: string;
}

const FloatingLabel: FunctionComponent<FloatingLabelProps> = (
  props: FloatingLabelProps,
) => {
  const { label, children, className = "" } = props;

  return (
    <div className={`range-picker-label-container ${className}`}>
      {children}
      <label className="range-picker-title">{label}</label>
    </div>
  );
};

export default FloatingLabel;
