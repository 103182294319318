import * as t from "io-ts";

export const errorCodeItemResponseDtoDecoderContent = {
  codeValue: t.string,
  label: t.string,
  message: t.string,
  module: t.string,
  feature: t.string,
};

export const errorCodeItemResponseDtoDecoder = t.type(
  errorCodeItemResponseDtoDecoderContent,
);

export type ErrorCodeItemResponseDto = t.TypeOf<
  typeof errorCodeItemResponseDtoDecoder
>;
