import { FunctionComponent, useEffect, useState } from "react";
import Section from "@components/core/section/Section";
import { useTranslation } from "react-i18next";
import { formValidateTriggers } from "@utils/Constant";
import { Form, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { DeviceDetailsResponseDto } from "@state/device/response/device.details.response.dto";
import { requestGetUserDeviceInfo } from "@state/device/DeviceEffects";
import { AnyObject } from "antd/es/_util/type";
import BasicTable from "@components/core/tables/BasicTable";
import { columns as mySmartphoneColumns } from "@views/core/admin/account/infos/form/columns/MySmartphoneColumns";

const MySmartphoneForm: FunctionComponent = () => {
  // GENERIC HOOKS
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const [isDeviceRetrieved, setIsDeviceRetrieved] = useState<boolean>(false);

  const [device, setDevice] = useState<DeviceDetailsResponseDto[]>([]);

  const spinIcon = <LoadingOutlined spin />;

  useEffect(() => {
    void requestGetUserDeviceInfo({
      id: "",
    });
    setIsDeviceRetrieved(false);
  }, []);

  useEffect(() => {
    return requestGetUserDeviceInfo.done.watch(({ result }) => {
      if (result.ok && result.data) {
        setDevice([result.data]);
      }
      setIsDeviceRetrieved(true);
    });
  }, []);

  return (
    <div className="mt-42">
      {!isDeviceRetrieved ? (
        <Section title={t("users.sections.mySmartphone.title")}>
          <div className="text-center">
            <Spin indicator={spinIcon} />
          </div>
        </Section>
      ) : device.length > 0 ? (
        <Section title={t("users.sections.mySmartphone.title")}>
          <Form {...formValidateTriggers} layout="vertical" form={form}>
            <BasicTable
              dataSource={device}
              columns={mySmartphoneColumns()}
              rowClassName={(record: AnyObject) =>
                record.startPeriod && record.endPeriod
                  ? new Date() > new Date(record.endPeriod as string)
                    ? "row-danger"
                    : ""
                  : "row-warning"
              }
            />
          </Form>
        </Section>
      ) : (
        <Section
          title={t("users.sections.mySmartphone.title")}
          description={t("users.sections.mySmartphone.noDevice")}
        >
          <div></div>
        </Section>
      )}
    </div>
  );
};
export default MySmartphoneForm;
