import { createEvent } from "effector";
import { AuthenticationContext } from "@type/core/auth/auth.types";
import { AuthenticatedUserResponseDto } from "@state/auth/dto/response/authenticated.user.response.dto";
import { AuthResultResponseDto } from "@state/auth/dto/response/auth.result.response.dto";

export const authenticationContextEvent = createEvent<
  AuthenticationContext | undefined
>();

export const login = createEvent<AuthResultResponseDto>();

export const setUser = createEvent<AuthenticatedUserResponseDto>("SET_USER");

export const clearUser = createEvent("CLEAR_USER");

export const logout = createEvent();
