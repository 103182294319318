import { ReactElement, useState } from "react";
import { useTranslation } from "react-i18next";
import { Form, FormInstance, Select } from "antd";
import { OptionGroupType, OptionType } from "@type/core/form/field.types";
import { Rule } from "antd/es/form";
import { formHelper } from "@utils/helpers/form-helper";
import { basicMandatoryRule } from "@components/core/inputs/InputFormRules";
import { FilterFunc } from "rc-select/lib/Select";
import FormFieldLabel from "@components/core/labels/FormFieldLabel";
import FloatingLabel from "@components/core/inputs/FloatingLabel";

const { Option, OptGroup } = Select;

export interface SelectFieldProps {
  module: string;
  field: string;
  options?: OptionType[];
  optionGroups?: OptionGroupType[];
  required?: boolean;
  placeholder?: string;
  showTooltip?: boolean;
  labelTooltip?: string;
  readOnly?: boolean;
  labelClassName?: string;
  fieldClassName?: string;
  mode?: "multiple" | "tags";
  onSelect?: (value: string) => void;
  fieldPrefix?: string;
  rules?: Rule[];
  onChange?: (value: string[] | string) => void;
  onSearch?: (value: string) => void;
  onClick?: () => void;
  initialValue?: string;
  allowClear?: boolean;
  className?: string;
  disabled?: boolean;
  showSearch?: boolean;
  optionFilterProp?: string;
  filterOption?: boolean | FilterFunc<OptionType>;
  filterSort?: (optionA: OptionType, optionB: OptionType) => number;
  loading?: boolean;
  inModal?: boolean;
  form: FormInstance;
}

const SelectFormField = ({
  showTooltip,
  labelTooltip,
  module,
  field,
  required,
  readOnly,
  labelClassName = "",
  fieldClassName = "",
  options,
  optionGroups,
  rules = [],
  mode,
  onSelect,
  onChange,
  onSearch,
  onClick,
  initialValue,
  fieldPrefix = "",
  allowClear,
  className = "",
  disabled,
  placeholder,
  showSearch,
  optionFilterProp,
  filterOption,
  filterSort,
  loading,
  inModal,
  form,
}: SelectFieldProps): ReactElement => {
  const { t } = useTranslation();
  const [forceLabelRerender, setForceLabelRerender] = useState<boolean>(false);

  const fieldRules: Rule[] =
    !readOnly && required
      ? [
          ...rules,
          basicMandatoryRule({
            field,
            constraints: {
              isRequired: required,
              isBlankAllowed: mode !== "multiple",
            },
          }),
        ]
      : rules;

  const i18nField = formHelper.getI18nFieldFromField(field);

  const key = `field_${String(field)}`.replace(",", "_");

  const inputPlaceholder = placeholder
    ? placeholder
    : t(`${module}.fields.${i18nField}.placeholder`);

  return (
    <FloatingLabel
      form={form}
      field={`${fieldPrefix}${String(field)}`}
      label={
        <FormFieldLabel
          field={field}
          module={module}
          label={inputPlaceholder}
          className={labelClassName}
          showTooltip={showTooltip}
          tooltipLabel={labelTooltip}
        />
      }
      forceLabelRerender={forceLabelRerender}
    >
      <Form.Item
        name={`${fieldPrefix}${String(field)}`}
        key={key}
        initialValue={initialValue}
        rules={fieldRules}
        className={className || ""}
      >
        <Select
          getPopupContainer={
            inModal
              ? formHelper.getInModalPopupContainer
              : formHelper.getPopupContainer
          }
          mode={mode}
          disabled={readOnly ?? disabled ?? false}
          id={key}
          placeholder={inputPlaceholder}
          onSearch={onSearch}
          onClick={onClick}
          onSelect={onSelect}
          onChange={onChange}
          onDeselect={() => {
            setForceLabelRerender(!forceLabelRerender);
          }}
          data-test={field}
          data-test-type="select-list"
          className={fieldClassName}
          allowClear={allowClear}
          showSearch={showSearch}
          optionFilterProp={optionFilterProp}
          filterOption={filterOption}
          filterSort={filterSort}
          loading={loading}
        >
          {options?.map((option) => (
            <Option
              key={`value_${option.value}`}
              value={option.value}
              data-test="select-list-option"
              label={option.label}
            >
              {t(option.label)}
            </Option>
          ))}
          {optionGroups?.map((group) => (
            <OptGroup key={group.id} label={<>{t(group.label)}</>}>
              {group.options.map((option) => (
                <Option
                  key={`value_${option.value}`}
                  value={option.value}
                  data-test="select-list-option"
                >
                  {t(option.label)}
                </Option>
              ))}
            </OptGroup>
          ))}
        </Select>
      </Form.Item>
    </FloatingLabel>
  );
};

export { SelectFormField };
