import * as t from "io-ts";
import { UserProfileEnumV } from "@type/core/user/profile.types";
import { UserStatusEnumV } from "@type/core/user/status.types";

export const authenticatedUserResponseDtoDecoderContent = {
  id: t.string,
  ordinalNumber: t.union([t.string, t.undefined, t.null]),
  firstName: t.string,
  lastName: t.string,
  email: t.union([t.string, t.undefined]),
  profiles: t.array(UserProfileEnumV),
  status: UserStatusEnumV,
  passwordExpired: t.boolean,
  language: t.string,
  phoneNumber: t.union([t.string, t.null]),
};

export const authenticatedUserResponseDtoDecoder = t.type(
  authenticatedUserResponseDtoDecoderContent,
);

export type AuthenticatedUserResponseDto = t.TypeOf<
  typeof authenticatedUserResponseDtoDecoder
>;
