import { Rule } from "antd/lib/form";
import i18n from "i18next";
import { InternalNamePath } from "rc-field-form/lib/interface";

interface BasicMandatoryRuleParams {
  field: string | InternalNamePath;
  emptyErrorMessage?: string;
  constraints: {
    isRequired: boolean;
    isBlankAllowed?: boolean;
  };
}

export const basicMandatoryRule = ({
  field,
  emptyErrorMessage,
  constraints: { isRequired, isBlankAllowed = false },
}: BasicMandatoryRuleParams): Rule => {
  const rule: Rule = {
    required: isRequired,
    message: (
      <span
        data-test={`error-${String(field)}`}
        data-testid={`error-${String(field)}`}
      >
        {emptyErrorMessage ? (
          <>{emptyErrorMessage}</>
        ) : (
          i18n.t("forms.errors.mandatory")
        )}
      </span>
    ),
  };

  if (isBlankAllowed) {
    rule.whitespace = isRequired;
  }

  return rule;
};
