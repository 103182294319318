import { OptionType } from "@type/core/form/field.types";

export enum UserStatus {
  INIT = "INIT",
  INIT_WITH_SSO = "INIT_WITH_SSO",
  ACTIVE = "ACTIVE",
  DISABLED = "DISABLED",
  INACTIVE = "INACTIVE",
  PENDING = "PENDING",
}

export interface UserStatusProperties {
  label: string;
  variant: string;
}

export const userStatusProperties: Record<UserStatus, UserStatusProperties> = {
  [UserStatus.INIT]: {
    label: "users.labels.status.INIT",
    variant: "grey",
  },
  [UserStatus.INIT_WITH_SSO]: {
    label: "users.labels.status.INIT",
    variant: "grey",
  },
  [UserStatus.ACTIVE]: {
    label: "users.labels.status.ACTIVE",
    variant: "success",
  },
  [UserStatus.DISABLED]: {
    label: "users.labels.status.DISABLED",
    variant: "danger",
  },
  [UserStatus.INACTIVE]: {
    label: "users.labels.status.INACTIVE",
    variant: "black",
  },
  [UserStatus.PENDING]: {
    label: "users.labels.status.PENDING",
    variant: "grey",
  },
} as const;

export const USERS_STATUS = Object.keys(UserStatus)
  .map((key) => key as UserStatus)
  .map((key) => ({
    value: key,
    label: userStatusProperties[key].label,
  })) as OptionType[];

export enum UserTransitionEnum {
  ENABLE = "ENABLE",
  DISABLE = "DISABLE",
  CONFIRM_REGISTRATION = "CONFIRM_REGISTRATION",
}

export interface UserTransitionProperties {
  id: UserTransitionEnum;
  label: string;
  variant: string;
}

export type UserTransitionsProperties = {
  [key in UserTransitionEnum]: UserTransitionProperties;
};

export const userTransitions: UserTransitionsProperties = {
  [UserTransitionEnum.ENABLE]: {
    id: UserTransitionEnum.ENABLE,
    label: "users.labels.transitions.ENABLE",
    variant: "success",
  },
  [UserTransitionEnum.DISABLE]: {
    id: UserTransitionEnum.DISABLE,
    label: "users.labels.transitions.DISABLE",
    variant: "danger",
  },
  [UserTransitionEnum.CONFIRM_REGISTRATION]: {
    id: UserTransitionEnum.CONFIRM_REGISTRATION,
    label: "users.labels.transitions.CONFIRM_REGISTRATION",
    variant: "secondary",
  },
};
