import { createEffect } from "effector";
import {
  restCreationMultipartHandler,
  restDeletionHandler,
  restDetailsHandler,
  restListHandler,
  restListHandlerPagination,
  restPostHandler,
} from "@utils/rest/RestClient";
import { TransactionSearchCriteria } from "@state/transactions/dto/request/transaction.search.criteria";
import {
  TransactionItemResponseDto,
  transactionItemResponseDtoDecoder,
} from "@state/transactions/dto/response/transaction.item.response.dto";
import configuration from "@utils/Config";
import {
  createPageableDecoder,
  createPageableDecoderContent,
} from "@type/core/list/pagination.types";
import {
  DocumentContentResponseDto,
  DocumentContentResponseDtoDecoder,
  DocumentContentResponseDtoDecoderContent,
} from "@state/transactions/dto/response/document.content.response.dto";
import {
  TransactionDetailsResponseDto,
  transactionDetailsResponseDtoDecoder,
  transactionDetailsResponseDtoDecoderContent,
} from "@state/transactions/dto/response/transaction.details.response.dto";
import {
  ProofFileContentResponseDto,
  ProofFileContentResponseDtoDecoder,
  ProofFileContentResponseDtoDecoderContent,
} from "@state/transactions/dto/response/proof.file.content.response.dto";
import {
  submitterNameResponseDtoDecoder,
  SubmitterNameResponseDto,
} from "@state/transactions/dto/response/submitter.names.response.dto";

const transactionsBaseUrl = `${configuration.backendBaseUrl}/transactions`;
const dashboardUrl = `${transactionsBaseUrl}/search`;
const webDashboardUrl = `${dashboardUrl}/web`;

const dashboardPageableDecoderContent = createPageableDecoderContent(
  transactionItemResponseDtoDecoder,
);

const dashboardPageableDecoder = createPageableDecoder(
  transactionItemResponseDtoDecoder,
);
export const requestGetDashboardDocuments = createEffect({
  handler: restListHandlerPagination<
    TransactionItemResponseDto,
    TransactionSearchCriteria,
    typeof dashboardPageableDecoderContent,
    typeof dashboardPageableDecoder
  >(webDashboardUrl, dashboardPageableDecoder),
});

export const requestGetDocumentContent = createEffect({
  handler: restDetailsHandler<
    DocumentContentResponseDto,
    typeof DocumentContentResponseDtoDecoderContent,
    typeof DocumentContentResponseDtoDecoder
  >(
    transactionsBaseUrl + "/",
    "/documents/",
    DocumentContentResponseDtoDecoder,
  ),
});
export const requestGetSubmitterNames = createEffect({
  handler: restListHandler<
    SubmitterNameResponseDto,
    Record<string, unknown>,
    typeof submitterNameResponseDtoDecoder
  >(transactionsBaseUrl + "/requester"),
});

export const requestRemindRecipient = createEffect({
  handler: restPostHandler(transactionsBaseUrl + "/"),
});

export const requestCreateTransaction = createEffect({
  handler: restCreationMultipartHandler<
    TransactionDetailsResponseDto,
    typeof transactionDetailsResponseDtoDecoderContent,
    typeof transactionDetailsResponseDtoDecoder
  >(transactionsBaseUrl, undefined, transactionDetailsResponseDtoDecoder),
});

export const requestProofFileContent = createEffect({
  handler: restDetailsHandler<
    ProofFileContentResponseDto,
    typeof ProofFileContentResponseDtoDecoderContent,
    typeof ProofFileContentResponseDtoDecoder
  >(
    transactionsBaseUrl + "/",
    "/proof-file",
    ProofFileContentResponseDtoDecoder,
  ),
});

export const requestDeleteSignedDocument = createEffect({
  handler: restDeletionHandler(`${transactionsBaseUrl}/`, "/documents/"),
});
