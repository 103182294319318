import { cookieHelper } from "@utils/helpers/cookie-helper";

export const removeBase64UrlPrefix = (base64File: string): string => {
  const i = base64File.indexOf("base64,");
  return base64File.slice(i + 7);
};

export const getBase64 = (
  img: Blob,
  callback: (imageUrl: string | ArrayBuffer | null) => void,
): void => {
  const reader = new FileReader();
  reader.addEventListener("load", () => {
    callback(removeBase64UrlPrefix(reader.result as string));
  });
  reader.readAsDataURL(img);
};

export const getBase64UrlFrom = (base64: string, type: string): string => {
  return `data:${type};base64,${base64}`;
};

export const debugBase64 = (base64URL: string): void => {
  const win = window.open();
  if (win) {
    win.document.write(
      `<iframe src="${base64URL}" frameborder="0" style="border:0; top:0; left:0; bottom:0; right:0; width:100%; height:100%;" allowfullscreen></iframe>`,
    );
  }
};

let interval: number;
let attempts = 60;

const computeQueryParamPrefix = (url: string): string => {
  return url.includes("?") ? "&" : "?";
};

export const downloadFile = (
  url: string,
  filename: string,
  downloadToken?: string,
  callback?: () => void,
): void => {
  const link = document.createElement("a");
  link.href = `${url}${
    downloadToken
      ? `${computeQueryParamPrefix(url)}downloadToken=${downloadToken}`
      : ""
  }`;
  link.download = filename;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  if (callback && downloadToken) {
    interval = window.setInterval(() => {
      attempts--;
      const cookie = cookieHelper.getCookie("downloadToken");
      if (cookie === downloadToken.toString() || attempts === 0) {
        window.clearInterval(interval);
        callback();
        attempts = 30;
      }
    }, 1000);
  }
};

export const openFilePreview = (content: string, contentType: string) => {
  const base64ImageData = `data:${contentType};base64,${content}`;

  // const byteCharacters = window.atob(
  //   base64ImageData.substring(`data:${contentType};base64,`.length),
  // );
  //
  // const byteArrays = [];
  //
  // for (let offset = 0; offset < byteCharacters.length; offset += 1024) {
  //   const slice = byteCharacters.slice(offset, offset + 1024);
  //
  //   const byteNumbers = new Array(slice.length);
  //   for (let i = 0; i < slice.length; i++) {
  //     byteNumbers[i] = slice.charCodeAt(i);
  //   }
  //
  //   const byteArray = new Uint8Array(byteNumbers);
  //
  //   byteArrays.push(byteArray);
  // }
  // const blob = new Blob(byteArrays, { type: contentType });
  // const blobUrl = URL.createObjectURL(blob);

  const hiddenElement = document.createElement("iframe");
  hiddenElement.src = base64ImageData;
  hiddenElement.width = "100%";
  hiddenElement.height = "100%";
  window.open()?.document.body.appendChild(hiddenElement);
  // hiddenElement.target = "_blank";
  // hiddenElement.download = "toto.pdf";
  // hiddenElement.click();

  // window.open(base64ImageData, "_blank");
};
