import * as t from "io-ts";
import { endpointDetailsResponseDtoDecoder } from "@state/app/response/endpoint.details.response.dto";

export const endpointGroupDetailsResponseDtoDecoderContent = {
  label: t.string,
  endpoints: t.array(endpointDetailsResponseDtoDecoder),
};

export const endpointGroupDetailsResponseDtoDecoder = t.type(
  endpointGroupDetailsResponseDtoDecoderContent,
);

export type EndpointGroupDetailsResponseDto = t.TypeOf<
  typeof endpointGroupDetailsResponseDtoDecoder
>;
