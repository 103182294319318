import { useEffect, useState, JSX } from "react";
import BasicBanner from "@components/core/banners/BasicBanner";
import { LINK_DEFAULT_TITLE, LOCAL_STORAGE } from "@utils/Constant";
import { Trans, useTranslation } from "react-i18next";
import { ROUTES } from "@routes/Routes";
import configuration from "@utils/Config";
import BasicLink from "@components/core/links/BasicLink";

const CookiesBanner = (): JSX.Element => {
  const { t } = useTranslation();
  const [banner, setBanner] = useState<boolean>(false);

  const closeBanner = () => {
    setBanner(false);
    window.sessionStorage.setItem(LOCAL_STORAGE.COOKIE, "true");
  };

  useEffect(() => {
    const cookie = window.sessionStorage.getItem(LOCAL_STORAGE.COOKIE);
    if (!cookie) {
      setBanner(true);
    }
  }, []);

  return (
    <>
      {banner && configuration.cookieBannerActive && (
        <BasicBanner
          onClose={closeBanner}
          content={
            <>
              <Trans i18nKey={"banners.cookies.content"} />
              &nbsp;
              <BasicLink
                to={ROUTES.cookie.generate()}
                onClick={closeBanner}
                title={LINK_DEFAULT_TITLE}
              >
                {t("banners.cookies.link")}
              </BasicLink>
            </>
          }
        />
      )}
    </>
  );
};

export default CookiesBanner;
