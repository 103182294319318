import { FunctionComponent } from "react";
import { Progress } from "antd";

interface ProgressProps {
  percent?: number;
  disabled?: boolean;
  className?: string;
}

const BasicProgress: FunctionComponent<ProgressProps> = ({
  percent = 100,
  disabled = false,
  className = "",
}) => {
  return (
    <Progress
      className={`app-progress ${disabled ? "progress-disabled" : ""} ${className}`}
      percent={percent}
      size="small"
      showInfo={false}
    />
  );
};

export default BasicProgress;
