import { ChangeEvent, FunctionComponent } from "react";
import { Form, Input } from "antd";

interface Props {
  field: string;
  size: number;
  onComplete?: (value?: string) => void;
}

const separator = "-";

const Otp: FunctionComponent<Props> = (props: Props) => {
  const { field, size, onComplete } = props;

  const elem = [...Array<string>(size)].map(
    (e, i) => `${field}${separator}${i.toString()}`,
  );

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value, id } = e.target;
    const [fieldName, fieldIndex] = id.split(separator);

    // Check if they hit the max character length
    if (value.length >= 1) {
      // Check if it's not the last input field
      if (parseInt(fieldIndex, 10) < size) {
        // Get the next input field
        const nextSibling: HTMLInputElement | null = document.querySelector(
          `input[id=${fieldName}${separator}${(parseInt(fieldIndex, 10) + 1).toString()}]`,
        );

        // If found, focus the next field
        if (nextSibling !== null) {
          nextSibling.focus();
          nextSibling.setSelectionRange(0, nextSibling.value.length);
        } else {
          onComplete && onComplete(e.target.value);
        }
      }
    }
  };

  return (
    <>
      <div className="otpKey-container">
        {elem.map((e) => (
          <Form.Item name={e} key={e} className="ml-4 mr-4">
            <Input
              id={e}
              min={0}
              max={9}
              maxLength={1}
              onChange={handleChange}
              className="otpKey"
              autoComplete="off"
              data-test-type="input-text"
              data-test={e}
            />
          </Form.Item>
        ))}
      </div>
    </>
  );
};

export default Otp;
