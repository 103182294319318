import { FunctionComponent, ReactElement, JSX } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { FloatButton, Spin } from "antd";
import { LeftOutlined, ArrowUpOutlined } from "@ant-design/icons";
import Header from "@components/core/headers/Header";
import Footer from "@components/core/footers/Footer";
import BasicCard from "@components/core/cards/BasicCard";

interface PageLayoutProps {
  title?: string | JSX.Element | null;
  headerClassName?: string;
  bodyClassName?: string;
  container?: boolean;
  containerSize?: "sm" | "md" | "lg" | "xl" | "xxl";
  noHeader?: boolean;
  noFooter?: boolean;
  contentInCard?: boolean;
  children: string | ReactElement;
  isLoading?: boolean;
  returnRoute?: string;
  returnText?: string;
  backTop?: boolean;
  headerContent?: ReactElement;
}

const PageLayout: FunctionComponent<PageLayoutProps> = ({
  title = null,
  headerClassName = "",
  bodyClassName = "",
  container = true,
  containerSize = "xl",
  noHeader = false,
  noFooter = false,
  contentInCard = false,
  children,
  isLoading = false,
  returnRoute,
  returnText,
  backTop = false,
  headerContent,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className="layout">
      <div className="layout-container content-contained content-xxxl">
        {backTop && (
          <FloatButton.BackTop
            icon={<ArrowUpOutlined />}
            className="back-top-container"
          />
        )}
        {!noHeader && <Header />}
        <main>
          {isLoading ? (
            <div className="h-100 flex-grow-1 d-flex align-items-center justify-content-center">
              <Spin />
            </div>
          ) : (
            <div className="app-content">
              {(title ?? returnRoute ?? headerContent) && (
                <div className={`content-header-container ${headerClassName}`}>
                  <div
                    className={`content-header ${
                      container
                        ? `content-contained content-${containerSize}`
                        : ""
                    }`}
                  >
                    {headerContent ? (
                      headerContent
                    ) : (
                      <>
                        {returnRoute && (
                          <div
                            className="page-return"
                            onClick={() => {
                              navigate(returnRoute);
                            }}
                          >
                            <div className="page-return-icon">
                              <LeftOutlined />
                            </div>
                            <span>
                              {returnText ? returnText : t("buttons.back")}
                            </span>
                          </div>
                        )}
                        {title && (
                          <h1
                            data-test="page-title"
                            className="text-white mb-0 it-title"
                          >
                            {title}
                          </h1>
                        )}
                      </>
                    )}
                  </div>
                </div>
              )}
              <div className={`content-body-container ${bodyClassName}`}>
                <div
                  className={`content-body ${
                    container
                      ? `content-contained content-${containerSize}`
                      : ""
                  }`}
                >
                  {contentInCard ? <BasicCard>{children}</BasicCard> : children}
                </div>
              </div>
            </div>
          )}
        </main>
        {!noFooter && <Footer />}
      </div>
    </div>
  );
};

export default PageLayout;
