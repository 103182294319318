import { FieldData, InternalNamePath } from "rc-field-form/lib/interface";
import i18n from "i18next";
import { FieldError, ParsedResponse } from "@utils/rest/ServerResponseParse";
import { FormInstance } from "antd";
import { toastError } from "@utils/helpers/toast-helper";
import { apiHelper } from "@utils/helpers/api.helper";

const getFormErrorLabel = (errorCode: string): string => {
  return i18n.t(`api.errors.codes.validation.${errorCode}`);
};

const buildFormErrors = (
  module: string,
  errors?: FieldError[],
): FieldData[] => {
  return (errors ?? []).map((error) => ({
    name: error.field,
    errors: [getFormErrorLabel(error.errorCode)],
  }));
};

const handleFormErrors = <T>(
  module: string,
  form: FormInstance,
  result: ParsedResponse<T>,
): void => {
  if (result.fieldErrors && result.fieldErrors.length > 0) {
    const errors = buildFormErrors(module, result.fieldErrors);
    form.setFields(errors);
  }
  if (result.globalErrors && result.globalErrors.length > 0) {
    result.globalErrors.forEach((error) => {
      toastError(error.message);
    });
  } else {
    toastError(
      result.errorCode
        ? i18n.t(apiHelper.computeErrorLabelKey(result.errorCode))
        : result.errorMessage,
    );
  }
};

const extractFieldFromString = (f: string): string => {
  return f.split(".")[f.split(".").length - 1];
};

const extractFieldFrom = (fieldItem: string): string => {
  return fieldItem.replace(/\d/g, "");
};

const extractFieldFromArray = (f: unknown): string => {
  if (!Array.isArray(f)) {
    return "";
  }
  return extractFieldFrom((f as string[])[f.length - 1]);
};

const getI18nFieldFromField = (field: string | InternalNamePath): string => {
  return typeof field === "string"
    ? extractFieldFromString(field)
    : extractFieldFromArray(field as string[]);
};

const computeTooltipKeyFromField = (
  field: string | InternalNamePath,
  module: string,
): string => {
  return `${module}.fields.${getI18nFieldFromField(field)}.tooltip`;
};

const getErrorMessage = (errorMessage: string | undefined): string => {
  return errorMessage ? errorMessage : i18n.t("forms.errors.mandatory");
};

const getPopupContainer = (triggerNode: HTMLElement): HTMLElement =>
  triggerNode.parentElement ?? triggerNode;

const getInModalPopupContainer = (triggerNode: HTMLElement): HTMLElement => {
  if (
    triggerNode.className.includes("ant-modal") ||
    triggerNode.tagName === "BODY"
  ) {
    return triggerNode;
  }

  return getInModalPopupContainer(triggerNode.parentElement ?? document.body);
};

export const formHelper = {
  buildFormErrors,
  handleFormErrors,
  getI18nFieldFromField,
  getErrorMessage,
  computeTooltipKeyFromField,
  getPopupContainer,
  getInModalPopupContainer,
};
