import { FunctionComponent } from "react";
import BasicLink from "@components/core/links/BasicLink";
import { useTranslation } from "react-i18next";
import { toastInfo } from "@utils/helpers/toast-helper";
import i18n from "i18next";

interface FooterProps {
  className?: string;
}

const Footer: FunctionComponent<FooterProps> = ({ className = "" }) => {
  const { t } = useTranslation();

  return (
    <footer className={className}>
      <div className="footer-container content-contained content-xl">
        <div className="link-container">
          {/* TODO: LINK -> correct url */}
          <BasicLink
            to="#"
            title={t("footer.cookies")}
            onClick={() => {
              toastInfo(i18n.t("features.message.missing-feature"));
            }}
          >
            {t("footer.cookies")}
          </BasicLink>
          {/* TODO: LINK -> correct url */}
          <BasicLink
            to="#"
            title={t("footer.cgu")}
            onClick={() => {
              toastInfo(i18n.t("features.message.missing-feature"));
            }}
          >
            {t("footer.cgu")}
          </BasicLink>
        </div>
        <div className="copyright">{t("footer.copyright")}</div>
      </div>
    </footer>
  );
};

export default Footer;
