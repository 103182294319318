import {
  UserProfile,
  UserProfileProperties,
  userProfilesProperties,
} from "@utils/enums/profile.enum";

export function getProfilePropertiesFrom(
  profile: UserProfile,
): UserProfileProperties {
  return userProfilesProperties[profile];
}

export function isProfileAmong(
  profile: UserProfile | undefined,
  expectedProfiles: UserProfile[],
): boolean {
  if (!profile) {
    return false;
  }

  return expectedProfiles.length === 0 || expectedProfiles.includes(profile);
}
