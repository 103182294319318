const getCookie = (name: string): string | undefined => {
  const parts = document.cookie.split(name + "=");
  if (parts.length === 2) {
    return parts.pop()?.split(";").shift();
  }
  return undefined;
};

export const cookieHelper = {
  getCookie,
};
