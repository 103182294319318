import { FunctionComponent } from "react";
import InputFormField, {
  InputFormFieldProps,
} from "@components/core/inputs/InputFormField";
import i18n from "i18next";

const MailFormField: FunctionComponent<InputFormFieldProps> = (
  props: InputFormFieldProps,
) => {
  const { rules, field } = props;

  return (
    <InputFormField
      {...props}
      rules={[
        ...(rules ?? []),
        {
          pattern:
            /[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/,
          message: (
            <span data-test={`error-${String(field)}`}>
              {i18n.t("forms.errors.email-invalid")}
            </span>
          ),
        },
      ]}
    />
  );
};

export default MailFormField;
