import coreTranslationFR from "@utils/i18n/fr/core/core-translations-fr";
import common from "./common.json";
import buttons from "./buttons.json";
import features from "./features.json";

const translationFR = {
  ...coreTranslationFR,
  ...common,
  ...buttons,
  ...features,
};

export default translationFR;
