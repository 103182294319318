import * as t from "io-ts";
import { featureErrorCodeItemResponseDtoDecoder } from "@state/app/response/feature.error.code.item.response.dto";

export const moduleErrorCodeItemResponseDtoDecoderContent = {
  module: t.string,
  label: t.string,
  featureErrorCodes: t.array(featureErrorCodeItemResponseDtoDecoder),
};

export const moduleErrorCodeItemResponseDtoDecoder = t.type(
  moduleErrorCodeItemResponseDtoDecoderContent,
);

export type ModuleErrorCodeItemResponseDto = t.TypeOf<
  typeof moduleErrorCodeItemResponseDtoDecoder
>;
