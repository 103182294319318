import { FunctionComponent, ReactNode } from "react";
import FormFieldTooltip from "@components/core/tooltips/FormFieldTooltip";
import { formHelper } from "@utils/helpers/form-helper";
import { InternalNamePath } from "rc-field-form/lib/interface";
import parse from "html-react-parser";

interface PropsType {
  module: string;
  field: string | InternalNamePath;
  label: string;
  className?: string;
  showTooltip?: boolean;
  tooltipLabel?: ReactNode | string;
}

const FormFieldLabel: FunctionComponent<PropsType> = ({
  field,
  module,
  label,
  className,
  showTooltip,
  tooltipLabel,
}) => {
  const computeTooltip = (): ReactNode | string => {
    if (!showTooltip) {
      return "";
    }

    return (
      tooltipLabel ??
      parse(formHelper.computeTooltipKeyFromField(field, module))
    );
  };

  return (
    <>
      <span
        data-test="input-label"
        data-alt={label}
        data-test-for={String(field)}
        className={className}
      >
        {label}&nbsp;
        {showTooltip && <FormFieldTooltip title={computeTooltip()} />}
      </span>
    </>
  );
};

export default FormFieldLabel;
