import * as t from "io-ts";

export const DocumentContentResponseDtoDecoderContent = {
  base64Data: t.string,
  mimeType: t.string,
  filename: t.string,
};

export const DocumentContentResponseDtoDecoder = t.type(
  DocumentContentResponseDtoDecoderContent,
);

export type DocumentContentResponseDto = t.TypeOf<
  typeof DocumentContentResponseDtoDecoder
>;
