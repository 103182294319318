import { PreferenceTypeEnum } from "@/utils/enums/preferences.enum";
import { UserProfile } from "@utils/enums/profile.enum";
import * as t from "io-ts";

type PreferenceContextBaseType = Partial<Record<PreferenceTypeEnum, string>>;

export interface PreferenceContext extends PreferenceContextBaseType {
  [PreferenceTypeEnum.SELECTED_PROFILE]?: UserProfile;
}

export const PreferenceEnumV = t.keyof(
  Object.fromEntries(
    Object.keys(PreferenceTypeEnum).map((p) => [p, null]),
  ) as Record<PreferenceTypeEnum, null>,
);
