import { Modal } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons/lib";
import i18n from "i18next";
import { ModalProperties } from "@type/core/modal/modal.types";

const { confirm } = Modal;

export const showConfirm = async (props: ModalProperties): Promise<boolean> => {
  return new Promise<boolean>((resolve) => {
    confirm({
      title: props.title,
      content: props.content,
      icon: <QuestionCircleOutlined />,
      okText: (
        <span data-test="confirm-button">{i18n.t("buttons.confirm")}</span>
      ),
      cancelText: (
        <span data-test="cancel-button">{i18n.t("buttons.cancel")}</span>
      ),
      centered: true,
      onOk() {
        resolve(true);
      },
      onCancel() {
        resolve(false);
      },
    });
  });
};
