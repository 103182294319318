import { FunctionComponent } from "react";
import PageLayout from "@components/core/layouts/PageLayout";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import BasicButton from "@components/core/buttons/BasicButton";
import { ROUTES } from "@routes/Routes";

const Error404Screen: FunctionComponent = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <PageLayout
      container={false}
      contentInCard
      bodyClassName="app-error-screen h-100 d-flex align-items-center justify-content-center"
    >
      <>
        <div className="error-text-container py-12 d-flex flex-column align-items-center justify-content-center">
          <div className="error-text-404 mb-8">{t("error.404")}</div>
          <h3 className="text-center">{t("error.pageNotFound")}</h3>
          <div className="text-center">{t("error.notExist")}</div>
          <BasicButton
            text={t("error.goBack")}
            onClick={() => {
              navigate(ROUTES.connected.home.generate());
            }}
            className="mt-24"
          />
        </div>
      </>
    </PageLayout>
  );
};

export default Error404Screen;
