import * as t from "io-ts";
import { CheckDocumentAuthenticityEnum } from "@type/core/document/check.document.authenticity.type";

export const checkDocumentAuthenticationDetailsResponseDtoDecoderContent = {
  documentAuthenticityEnum: CheckDocumentAuthenticityEnum,
};

export const checkDocumentAuthenticationDetailsResponseDtoDecoder = t.type(
  checkDocumentAuthenticationDetailsResponseDtoDecoderContent,
);

export type CheckDocumentAuthenticationDetailsResponseDto = t.TypeOf<
  typeof checkDocumentAuthenticationDetailsResponseDtoDecoder
>;
